import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Box, Select, MenuItem, IconButton, Container, Menu } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BookIcon from '@mui/icons-material/Book';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { profileImageSaved } from '../store/actions/authActions';
import { getProfileApi } from '../utils/apiCalls';
import LoginDrawer from './LoginDrawer';
import { COLORS } from '../constants/Theme';
import StethoscopeIcon from '@mui/icons-material/LocalHospital';
import Images from '../constants/Images.js';

const styles = {
  root: {
    backgroundColor: '#ffe6f2',
    padding: 0,
    margin: 0,
  },
  dropdown: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    height: '25px',
    textTransform: 'none', // Disable text transformation
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    padding: '0px', 
    '@media (min-width: 600px)': {
      padding: '0 50px', 
    },
  },
  toolbar1: {
    display: 'flex',
    justifyContent: 'flex-end', // Aligns menu to the right
    backgroundColor: '#ffe6f2',
    padding: '0px',
    '@media (min-width: 600px)': {
      padding: '0px', 
    },
  },
  button: {
    color: COLORS.primaryColor,
    textTransform: 'none', // Disable text transformation
    '&:hover': {
      backgroundColor: 'transparent', // Remove background on hover
      color: '#972168',
    },
    '&.active': {
      fontWeight: 'bold',
      borderBottom: '2px solid #972168',
      color: COLORS.primaryColor,
    },
    marginRight: '10px',
    '@media (max-width: 600px)': {
      marginRight: '5px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'left',
    marginRight: '20px',
  },
  menuIcon: {
    marginRight: '10px',
    color: COLORS.placeholderColor,
  },
  menuText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    marginTop: '5px',
    color: COLORS.placeholderColor,
    textTransform: 'none', // Disable text transformation
    '@media (max-width: 600px)': {
      display: 'none', 
    },
  },
  userButton: {
    color: COLORS.primaryColor,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none', // Disable text transformation
    '&:hover': {
      backgroundColor: 'transparent', // Remove black box on hover
      color: '#972168',
    },
  },
  header: {
    display: 'flex',
    flex: 0,
    margin: '0px',
    width: '100%',
    height: '80px',
    backgroundColor: '#FFFFFF',
    padding: '0px',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      height: '60px',
    },
  },
  logo: {
    height: '70px',
    marginLeft: '20px',
    '@media (max-width: 600px)': {
      height: '50px',
      marginLeft: '10px',
    },
  },
  locationSection: {
    display: 'flex',
    color: COLORS.primaryColor,
    alignItems: 'center',
    font: "normal normal normal 18px/27px Poppins",
    letterSpacing: "0px",
    width: '24px',
    height: '35px',
    '@media (max-width: 600px)': {
      fontSize: '14px',
      width: '20px',
      height: '30px',
    },
  },
  exicon: {
    color: COLORS.primaryColor,
  },
};

const Header = ({ dropdownOptions, selectedUnit, onUnitChange, slotType }) => {
  // const classes = useStyles;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event) => {
    onUnitChange(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeMenuStyle = (path) => {
    return location.pathname === path ? { color: COLORS.primaryColor } : { color: COLORS.placeholderColor };
  };

  const handleMhclist = () => {
    const queryParams = new URLSearchParams({
      dropdownOptions: dropdownOptions,
      selectedUnit: selectedUnit,
    }).toString();
    history.push(`/mhclist?${queryParams}`);
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    onCallProfileApi();
  }, []);

  const onCallProfileApi = async () => {
    const response = await getProfileApi();
    if (isResponseIsValid(response)) {
      dispatch(profileImageSaved(response.data.photo));
      setName(response.data.name);
    }
  };

  const handleBookinglist = () => {
    history.push('/specialitydoctor');
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <Container style={styles.root}>
      <AppBar position="static" style={styles.header}>
        <Toolbar style={styles.toolbar}>
          <img src={Images.kauveryLogo} alt="Logo" style={styles.logo} />
          {dropdownOptions && dropdownOptions.length > 0 ? (
            <Box justifyContent='center' style={styles.locationSection}>
              <LocationOnIcon />
              <Select
                value={selectedUnit}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select location' }}
                IconComponent={ArrowDropDownIcon}
                sx={{ minWidth: '200px', maxWidth: '100%', ...styles.dropdown }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  {selectedUnit}
                </MenuItem>
                {dropdownOptions.map(option => (
                  <MenuItem key={option.id} value={option.UnitName}>
                    {option.UnitName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ) : null}
          <IconButton color="inherit">
            <FontAwesomeIcon icon={faBell} style={{ color: '#962067' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar style={styles.toolbar1}>
        <Box display="flex" alignItems="center">
          {/* {slotType && (
            <Box style={styles.menuItem}>
              <Button
                startIcon={<LocalHospitalIcon />}
                style={{
                  ...styles.button,
                  ...(slotType === 'Walk In' ? activeMenuStyle('/appointmentbook') : { color: COLORS.placeholderColor })
                }}
                onClick={() => history.push('/appointmentbook')}
              >
                Hospital visit
              </Button>
              <Button
                startIcon={<VideoCallIcon />}
                style={slotType === 'Tele Consult' ? activeMenuStyle('/appointmentbook') : { color: COLORS.placeholderColor }}
                onClick={() => history.push('/appointmentbook')}
              >
                Video Consult
              </Button>
            </Box>
          )} */}
          <Button startIcon={<HomeIcon />} style={activeMenuStyle('/')} onClick={() => history.push('/')}>Home</Button>
          <Button startIcon={<NoteIcon />} style={activeMenuStyle('/specialitydoctor')} onClick={handleBookinglist}>Consult</Button>
          <Button startIcon={<BookIcon />} style={activeMenuStyle('/mhclist')} onClick={handleMhclist}>Mhc</Button>
          <Button startIcon={<StethoscopeIcon />} style={activeMenuStyle('/appointments')} onClick={() => history.push('/appointments')}>Records</Button>
        </Box>
        {name ? (
          <div>
            <Button style={styles.userButton} onClick={handleClick}>
              <Typography>{name}</Typography>
              <ExpandMoreIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  border: '1px solid #d3d4d5',
                  boxShadow: 'none',
                },
              }}
            >
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
              <MenuItem onClick={() => { history.push('/logout'); handleClose(); }}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <Button style={styles.userButton} onClick={handleDrawerOpen}>
            Login
          </Button>
        )}
        <LoginDrawer open={isDrawerOpen} onClose={handleDrawerClose} />
      </Toolbar>
    </Container>
  );
};

export default Header;
