import { COLORS, FONTS } from '../constants/Theme';
import BackgroundImage from '../assets/images/bg1.jpg'; 

const styles = {
  OTPContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: '#FFFFFFCC',
  },
  backgroundBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '120%',
    backgroundPosition: '150%',
    filter: 'blur(0px)',
    zIndex: -1,
  },
  logoContainer: {
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    maxWidth: '25%',
    height: 'auto',
  },
  mainContent: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    maxWidth: '500px',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleView: {
    marginBottom: '20px',
  },
  title: {
    ...FONTS.titleLarge,
    fontSize: '22px',
    color: COLORS.textColor,
    textAlign: 'center',
  },
  OtpContainerView: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  otpInput: {
    width: '50px', 
    height: '50px', 
    margin: '0 5px', 
    textAlign: 'center',
    fontSize: '16px',
    border: `1px solid ${COLORS.borderColor}`,
    borderRadius: '5px',
    backgroundColor: COLORS.whiteColor,
  },
  errorText: {
    //  ...FONTS.bodyMedium,
    color: COLORS.errorColor,
    fontSize: '14px',
    textAlign: 'left',
    marginTop: '8px',
  },
  timerContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  timerText: {
    color: COLORS.textColor,
    textAlign: 'center',
  },
  editnum: {
    color: COLORS.primaryColor,
    textAlign: 'center',  
    marginLeft: '160px',
    fontSize: '14px'
  },
  attemptsText: {
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
  },
  timerText2: {
    color: COLORS.primaryColor,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  resendButton: {
    width: '100px',
    height: '40px',
    borderRadius: '28px', 
    backgroundColor: COLORS.primaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '20px', 
    paddingVertical: '120px', 
    borderWidth: '1px',
    // borderColor: '',  
    // borderWidth: '1px',
    // borderColor: COLORS.primaryColor, 
    cursor: 'pointer',  
    color: COLORS.whiteColor,
    fontSize: FONTS.buttonTitle.fontSize, 
  },  
  spinner: {
    marginRight: '10px', 
    color: '#ffffff', 
  },
  otpVerifyButtonView: {
    marginTop: '20px',
    textAlign: 'center',
  },
  otpVerifyButton: {
    width: '200px',
    height: '50px',
    fontSize: '18px',
    backgroundColor: COLORS.primaryColor,
    color: COLORS.whiteColor,
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  successMessage: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.successColor,
    color: COLORS.whiteColor,
    padding: '10px',
    textAlign: 'center',
    zIndex: 9999,
  },
  // Media queries
  '@media (max-width: 768px)': {
    mainContent: {
      width: '90%',
      maxWidth: '300px',
      padding: '15px',
    },
    otpInput: {
      width: '40px',
      height: '40px',
      fontSize: '20px',
      margin: '0 3px',
    },
    resendButton: {
      width: '160px',
      height: '40px',
      fontSize: '16px',
    },
    otpVerifyButton: {
      width: '150px',
      height: '40px',
      fontSize: '16px',
    },
  },
  '@media (max-width: 480px)': {
    mainContent: {
      width: '95%',
      maxWidth: '280px',
      padding: '10px',
    },
    otpInput: {
      width: '35px',
      height: '35px',
      fontSize: '18px',
      margin: '0 2px',
    },
    resendButton: {
      width: '130px',
      height: '35px',
      fontSize: '14px',
    },
    otpVerifyButton: {
      width: '130px',
      height: '35px',
      fontSize: '14px',
    },
  },
};

export default styles;
