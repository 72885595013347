import React, { useEffect, useState, useRef } from 'react';
import { AppBar, Toolbar, Typography, Menu, MenuItem, Select, IconButton, Button, InputAdornment, FormControl, OutlinedInput, Box, Container, Tab, Tabs, Grid, Avatar, Card, CardContent } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import styles from './home.styles.js';
import Images from '../constants/Images.js';
import VideoComponent from './VideoComponent.js';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import PersonIcon from '@mui/icons-material/Person';
import { COLORS } from '../constants/Theme.js';
import HomeIcon from '@mui/icons-material/Home';
import NoteIcon from '@mui/icons-material/Note';
import StethoscopeIcon from '@mui/icons-material/LocalHospital';
import BookIcon from '@mui/icons-material/Book';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import LocationPopup from './LocationPopup';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material';
import {
    addFeedback,
    getFeedbackList,
    familyMembersList,
    getProfileApi,
    units,
    nearbyUnits,
    nearbyUnitsCoordinates,
    nearbyUnitsRegion,
    nearbyUnitsWithoutRegion,
    reScheduleBooking,
    specializationList,
    specializationList1,
    upcomingEvents,
    updateBooking,
    specializationListWithUnitID,
    doctorsListInConsultation,
    doctorsListInConsultationwithoutPagenation
} from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { profileImageSaved, unitNameData } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import LoginDrawer from '../components/LoginDrawer.js';
import { AppContext } from '../navigation/AppContext';
import {
    clearInfo,
    clearItem,
    getPayCheckoutSuccess,
    getSecondaryProfileID,
    getUserInformation,
    getWebHookCheckoutCall,
    setSecondaryProfileID,
    setUserInformation,
} from '../utils/LocalStorage';
import {
    currentRegion,
    currentProfileRawData
} from '../store/actions/homeActions';
import {
    customerCareCallNo,
    customerCareNumberData,
} from '../store/actions/bookingActions';
import Header from './HomeHeader.js';
import LoginBox from './LoginBox.js';
import ConsultationCard from './ConsultationCard.js';
import EventIcon from '@mui/icons-material/Event';
import RestoreIcon from '@mui/icons-material/Restore';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import LoginIcon from '@mui/icons-material/Login';

const Home = () => {
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const history = useHistory();
    const [token, setToken] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('Chennai - Mylapore');
    const [specialties, setSpecialities] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [unitAddress, setUnitAddress] = useState('No.199, Luz Church Road, Mylapore');
    const [showMoreSpecialties, setShowMoreSpecialties] = useState(false);
    const [showMoreDoctors, setShowMoreDoctors] = useState(false);
    const [spID, setSPID] = useState(10471000000018);
    const [name, setName] = useState('');
    const [unitid, setUnitid] = useState(13097000000024);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState('');
    const [locationPopupOpen, setLocationPopupOpen] = useState(false);
    const [locations, setLocations] = useState('');
    const [cancelBookingDetails, setCancelBookingDetails] = useState({});
    const [mhcCancelBookingDetails, setMhcCancelBookingDetails] = useState({});
    const [payAtHos, setPayAtHos] = useState(0);
    const [mhcReschedulePopup, setMhcReschedulePopup] = useState(false);
    const [isRescheduled, setIsRescheduled] = useState(false);
    const [requestId, setRequestId] = useState(false);
    const [specialityCount, setSpecialityCount] = useState(0);
    const [specialtiesList, setSpecialtiesList] = useState([]);
    const [requestPopup, setRequestPopup] = useState(false);
    const [cancelBookingPopup, setCancelBookingPopup] = useState(false);
    const [mhcCancelBookingPopup, setMhcCancelBookingPopup] = useState(false);
    const [page_size, setPage_size] = useState(80);
    const [page, setPage] = useState(1);
    const [initialLoader, setInitialLoader] = useState(false);
    const [next_page, setNext_page] = useState(null);
    const [doctorListCount, setDoctorListCount] = useState('');
    const [doctorsDetails, setDoctorsDetails] = useState([]);
    const [nameData, setNameData] = React.useState([]);
    const [relationData, setRelationData] = React.useState([]);
    const [showAll, setShowAll] = useState(false);
    const [showAll1, setShowAll1] = useState(false);
    const [showAll2, setShowAll2] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [search, setSearch] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [doctor, setDoctor] = useState([]);

    const currentRegionSelected = useSelector(
        // current region
        state => state?.homeReducer?.currentRegion,
    );
    const [city, setCity] = useState(currentRegionSelected);
    console.log(currentRegionSelected, 'reggggggg')
    // const { Auth } = useContext(AppContext);

    const [unitId, setUnitId] = useState('');
    const [unit, setUnits] = useState([]);
    const [loader, setLoader] = useState(false);

    const [tab, setTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        // console.log(newValue);
    };

    const displayedSpecialties = showAll ? specialtiesList : specialtiesList.slice(0, 6);
    const displayedDoctors = showAll1 ? doctorsDetails : doctorsDetails.slice(0, 4);
    const displayedEvent = showAll2 ? eventData : eventData.slice(0, 3);

    const slides = [
        {
            // heading: 'Book Consultation',
            // subheading: 'Book an online consultation or hospital visit with our in-house specialties',
            image: Images.family_front,
        },
        {
            heading: 'Book Consultation',
            subheading: 'Book an online consultation or hospital visit with our in-house specialties',
            image: Images.hospitalVisit,
        },
        {
            heading: 'Book a Master Health Check Up',
            subheading: 'Our access to the wide range of lab tests available',
            image: Images.scientist,
        },
        {
            heading: 'Get access to Health Records',
            subheading: 'Book an online consultation or hospital visit with our in-house specialties',
            image: Images.healthRecord,
        },
        {
            heading: 'Add Your Family Member',
            subheading: 'Add your family members to the account and keep track',
            image: Images.familyMember,
        },
    ];

    const previousValues = useRef({});

    useEffect(() => {
        console.log('Previous Values:', previousValues.current);
        console.log('Current Values:', {
            dropdownOptions,
            selectedUnit,
            unitAddress,
            unitId,
            city,
        });

        previousValues.current = { dropdownOptions, selectedUnit, unitAddress, unitId, city };
    }, [dropdownOptions, selectedUnit, unitAddress, unitId, city]);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(slideInterval);
    }, [slides.length]);

    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };


    console.log('twelvvvvvvvv', unit);

    useEffect(() => {
        unitDetails();
        nearbyUnitsWithoutCoordinates();

        // GettingLocationAccess();

        nearbyUnitsWithRegion(currentRegionSelected);
        onGetRelationList();
        // notificationBadgeUpdateApiCall();
        // pusherCallAPI();
        // getFeedbackListApiCall();
        onCallProfileApi();
        upcomingEventsApiCall();

        const handlePusherReloadFamilyMembers = () => {
            //   onGetRelationList();
        };

        const handleEventsViewAllReload = () => {
            //   updateUpcomingEventsApiCall();
        };

        const handleLocationHomeReload = (event) => {
            console.log('locationHomeReload', event.location);
            specialtiesListApiCall(unitId, 1, 4, event.location);
        };

        const handleRescheduleData = (event) => {
            //   notificationBadgeUpdateApiCall();
            console.log('RESCHEDULE EVENT', event);
            if (event.is_reschedule === 1) {
                setTimeout(() => {
                    setIsRescheduled(true);
                }, 500);
            }
            //   updateUpcomingEventsApiCall();
        };

        const handleMhcRescheduleData = (event) => {
            //   notificationBadgeUpdateApiCall();
            console.log('MHC RESCHEDULE EVENT', event);
            if (event.is_reschedule === 1) {
                setTimeout(() => {
                    setMhcReschedulePopup(true);
                }, 500);
            }
            //   updateUpcomingEventsApiCall();
        };

        const handleLogoutAction = () => {
            console.log('SPlash Event');
            setTimeout(() => {
                // Auth();
                snackBar('Oops! You have been logged out. Please sign-in again');
            }, 1200);
        };

        // const handleFeedbackPopup = (event) => {
        //   console.log('Event triggered successful');
        //   notificationBadgeUpdateApiCall();
        //   updateUpcomingEventsApiCall();
        //   console.log('Event', event);
        //   if (event.isShowPopup) {
        //     setTimeout(() => {
        //       setFeedBackPopup(true);
        //     }, 500);
        //   }
        // };

        const handleRequestNowData = (event) => {
            console.log('Event', event);
            if (event.isShowPopup) {
                setTimeout(() => {
                    if (event.item) {
                        setRequestId(event.item.request_id);
                        if (event.item.message === 'success') {
                            setRequestPopup(true);
                        }
                    }
                }, 500);
            }
        };

        const handleCancelBookingData = (event) => {
            //   notificationBadgeUpdateApiCall();
            //   updateUpcomingEventsApiCall();
            if (event.isShowPopup) {
                console.log('itemitem', event.item);
                setCancelBookingDetails(event.item);
                setPayAtHos(event.pay_at_hos);
                setTimeout(() => {
                    setCancelBookingPopup(true);
                }, 1500);
            }
        };

        const handleMhcCancelBookingData = (event) => {
            //   notificationBadgeUpdateApiCall();
            //   updateUpcomingEventsApiCall();
            if (event.isShowPopup) {
                console.log('mhc canceled booking', event.item);
                setMhcCancelBookingDetails(event.item);
                setPayAtHos(event.pay_at_hos);
                setTimeout(() => {
                    setMhcCancelBookingPopup(true);
                }, 1000);
            }
        };

        // Adding event listeners
        window.addEventListener('pusherReloadFamilyMembers', handlePusherReloadFamilyMembers);
        window.addEventListener('eventsViewAllReload', handleEventsViewAllReload);
        window.addEventListener('locationHomeReload', handleLocationHomeReload);
        window.addEventListener('reScheduleData', handleRescheduleData);
        window.addEventListener('mhcReScheduleData', handleMhcRescheduleData);
        window.addEventListener('Logout_action', handleLogoutAction);
        // window.addEventListener('feedbackPopup', handleFeedbackPopup);
        window.addEventListener('requestNowData', handleRequestNowData);
        window.addEventListener('cancelBookingData', handleCancelBookingData);
        window.addEventListener('mhcCancelBookingData', handleMhcCancelBookingData);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('pusherReloadFamilyMembers', handlePusherReloadFamilyMembers);
            window.removeEventListener('eventsViewAllReload', handleEventsViewAllReload);
            window.removeEventListener('locationHomeReload', handleLocationHomeReload);
            window.removeEventListener('reScheduleData', handleRescheduleData);
            window.removeEventListener('mhcReScheduleData', handleMhcRescheduleData);
            window.removeEventListener('Logout_action', handleLogoutAction);
            // window.removeEventListener('feedbackPopup', handleFeedbackPopup);
            window.removeEventListener('requestNowData', handleRequestNowData);
            window.removeEventListener('cancelBookingData', handleCancelBookingData);
            window.removeEventListener('mhcCancelBookingData', handleMhcCancelBookingData);
        };
    }, [unitId, dropdownOptions, unitAddress, city]);

    const upcomingEventsApiCall = async () => {
        setLoader(true);
        try {
            const response = await upcomingEvents(1, 10);
            console.log(JSON.stringify(response), 'EVENT RESPONSE');
            if (isResponseIsValid(response)) {
                console.log(JSON.stringify(response.data.data), 'EVENT RESPONSE DATA');
                setEventData(response.data.data);
                setTimeout(() => {
                    setLoader(false);
                }, 200);
            } else {
                setLoader(false);
                setTimeout(() => {
                    snackBar(JSON.stringify(response.data));
                }, 400);
            }
        } catch (err) {
            setLoader(false);
            setTimeout(() => {
                snackBar(JSON.stringify(err));
            }, 400);
        }
    };

    const onGetRelationList = async () => {
        // setListLoader(true)

        try {
            const response = await familyMembersList();
            if (isResponseIsValid(response)) {
                const relations = response.data.data;
                setRelationData(response.data.data);
                console.log('relatttttttt', response.data.data);
                // pusherCallAPI(relations);
                getSecondaryProfileID('Secondary_Profile_ID')
                    .then(res => {
                        let json = JSON.parse(res);
                        console.log('familyMembersList', response)
                        const relations = response.data.data;
                        relations.forEach(item => {
                            //check if the active profile is deleted if true check for primary profile id and set true
                            // if (item.app_profile_id === profile_information.app_profile_id) {
                            if (item.app_profile_id === json) {
                                item.isSelected = true;
                            } else {
                                item.isSelected = false;
                            }
                        });
                        setNameData(relations);
                        dispatch(currentProfileRawData(relations));
                        // // setListLoader(false)
                        // // if (
                        // //   relations.some(element => element.app_profile_id == json) == true
                        // // ) {
                        // //   relations.map(it => {
                        // //     if (it.app_profile_id === json) {
                        // //       // Mixpanel 
                        // //       console.log('mixpanel payload', it)
                        // //       mixpanel.getPeople().set("name", it.name);
                        // //       //  mixpanel.getPeople().set("email",)
                        // //       let profile_info = it;
                        // //       let property_input = {
                        // //         "Mobile number": profile_info.mobile_number,
                        // //         "Age": profile_info.age,
                        // //         "Gender": profile_info.gender,
                        // //         "Relationship": profile_info.relationship,
                        // //         "Is Primary User": profile_info.is_primary_user
                        // //       }
                        // //       mixpanel.track(mixpanel_event.DASHBOARD, property_input)
                        // //       dispatch(profileInformation(it))
                        // //       console.log('ITTTTTTTTTTTTTTTTTTTTT', it)
                        // //       dispatch(currentProfileName(it.name));
                        // //     }
                        // //   });
                        // // } else {
                        // //   setSecondaryProfileID(
                        // //     'Secondary_Profile_ID',
                        // //     JSON.stringify(relations[0].app_profile_id),
                        // //   )
                        // //     .then(() => {
                        // //       DeviceEventEmitter.emit('reloadUpload');
                        // //       mixpanel.getPeople().set("name", relations[0].name);
                        // //       let profile_info = relations[0];
                        // //       let property_input = {
                        // //         "Mobile number": profile_info.mobile_number,
                        // //         "Age": profile_info.age,
                        // //         "Gender": profile_info.gender,
                        // //         "Relationship": profile_info.relationship,
                        // //         "Is Primary User": profile_info.is_primary_user
                        // //       }
                        // //       mixpanel.track(mixpanel_event.DASHBOARD, property_input)
                        //       dispatch(profileInformation(relations[0]))

                        //       dispatch(currentProfileName(relations[0].name));
                        //     })
                        //     .catch(err => {
                        //       snackBar(JSON.stringify(err));
                        //     });
                        // }
                    })
                    .catch(err => {
                        // setListLoader(false)
                        console.log('err', err);
                    });
            } else {
                // setListLoader(false)
            }
        } catch (err) {
            //   setListLoader(false)
            setTimeout(() => {
                snackBar('eeeeeeeee', JSON.stringify(err));
            }, 400);
        }
    };

    const unitDetails = async () => {
        try {
            const response = await units();
            if (isResponseIsValid(response)) {
                // let unit = response.data.data.find(it => it.is_focused === true);
                // console.log('get units', unit);
                // dispatch(customerCareNumberData(unit.customer_care));
                // dispatch(customerCareCallNo(unit.customer_care_number));
                console.log('unitttttttttttttt', response.data);
                const unitNamesWithOids = response.data.data.map(it => ({
                    name: it.name,
                    oid: it._id.$oid
                }));
                setUnits(unitNamesWithOids);
            } else {
                setTimeout(() => {
                    if (response?.data) {
                        // snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    const nearbyUnitsWithRegion = async region => {
        try {
            const response = await nearbyUnitsRegion(region);
            if (isResponseIsValid(response)) {
                // let unit = response.data.data.find(it => it.is_focused === true);
                // console.log('get units', unit);
                // dispatch(customerCareNumberData(unit.customer_care));
                // dispatch(customerCareCallNo(unit.customer_care_number));
                console.log('uuuuuu', response.data);
                if (region !== '') {
                    setCity(region);
                    dispatch(currentRegion(region));
                } else if (response.data.current_region !== 'undefined') {
                    setCity(response.data.current_region);
                    dispatch(currentRegion(response.data.current_region));
                } else {
                    setCity(response.data.regions[0]);
                    dispatch(currentRegion(response.data.regions[0]));
                }
                setLocations(response.data.regions);

                if (
                    response.data.data[0]._id.$oid !== null ||
                    response.data.data[0]._id.$oid !== undefined
                ) {
                    setUnitId(response.data.data[0]._id.$oid);

                    console.log('CONSULT CUSTOMER CARE NUM', response.data.data[0]);
                    dispatch(unitNameData(response.data.data[0].name));
                    dispatch(customerCareNumberData(response.data.data[0].customer_care));
                    dispatch(customerCareCallNo(response.data.data[0].customer_care_number));

                    // combinedApiCall(unit._id.$oid, true);
                }
            } else {
                setTimeout(() => {
                    if (response?.data) {
                        // snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    useEffect(() => {
        const hasSeenPopup = localStorage.getItem('hasSeenLocationPopup');
        if (selectedUnit === '') {
            setLocationPopupOpen(true);
        }
    }, [selectedUnit]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUnitChange = (unit) => {
        // Handle unit change
    };

    const location = useLocation();
    const handleSeeMoreSpecialties = () => {
        setShowMoreSpecialties(!showMoreSpecialties);
    };

    const handleSeeMoreDoctors = () => {
        setShowMoreDoctors(!showMoreDoctors);
    };

    useEffect(() => {
        nearbyUnitsWithoutCoordinates();
        // GettingLocationAccess();
        // pusherCallAPI();
        // getFeedbackListApiCall();
        onCallProfileApi();
        combinedApiCall(unitId);
        // upcomingEventsApiCall();
    }, [unitId]);

    const combinedApiCall = async (unitid) => {
        try {
            // setLoader(true);

            console.log('UNIT ID PASSED IN API', unitid);
            //   const specialtiesResponse = await specializationListWithUnitID(
            //     unitId,
            //     1,
            //     4,
            //   );

            //   if (isResponseIsValid(specialtiesResponse)) {
            //     setSpecialityCount(specialtiesResponse.data.total_count);
            //     setSpecialtiesData(specialtiesResponse.data.data);
            //   } else {
            //     if (specialtiesResponse?.data) {
            //       snackBar(specialtiesResponse?.data);
            //     } else {
            //       snackBar(Headers.apiError);
            //     }
            //   }

            const doctorsResponse = await doctorsListInConsultation(
                unitid,
                currentRegionSelected,
                1,
                5,

            );

            if (isResponseIsValid(doctorsResponse)) {
                if (page != 1) {
                    setDoctorListCount(doctorsResponse.data.total_count);
                    setDoctorsDetails(doctorsResponse.data.data);
                    console.log('ddddddd', doctorsResponse.data.data);
                }
                else {
                    setDoctorsDetails(prevDoctorsDetails => [
                        ...prevDoctorsDetails,
                        ...doctorsResponse.data.data,
                    ]);
                    console.log('ddddddd', doctorsDetails);
                }
            } else {
                if (doctorsResponse?.data) {
                    snackBar(doctorsResponse?.data);
                } else {
                    snackBar(Headers.apiError);
                }
            }
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
            }, 400);
        } catch (error) {
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
                snackBar(Headers.apiError);
            }, 500);

        }
    };

    const nearbyUnitsWithoutCoordinates = async () => {
        setLoader(true);
        try {
            const response = await nearbyUnitsWithoutRegion();
            if (isResponseIsValid(response)) {
                const region = response.data.current_region || response.data.regions[0];
                setCity(region);
                dispatch(currentRegion(region));
                setLocations(response.data.regions);

                let unit = response.data.data.find(it => it.is_focused === true);
                dispatch(unitNameData(unit.name));
                dispatch(customerCareNumberData(unit.customer_care));
                dispatch(customerCareCallNo(unit.customer_care_number));
                setLoader(false);
            } else {
                setLoader(false);
                snackBar(response?.data || Headers.apiError);
            }
        } catch (err) {
            setLoader(false);
            snackBar(Headers.apiError);
        }
    };

    const onCallProfileApi = async () => {
        const response = await getProfileApi();
        console.log(response);
        if (isResponseIsValid(response)) {
            dispatch(profileImageSaved(response.data.photo ? response.data.photo : ''));
            setName(response.data.name);
        }
    };

    useEffect(() => {
        specialtiesListApiCall(unitid, page, page_size, city);
    }, [city, unitid, selectedUnit]);

    const specialtiesListApiCall = async (
        unitid,
        currentPage,
        pageSize,
        region,
    ) => {
        setLoader(true);
        try {
            const response = await specializationList1(
                unitid,
                currentPage,
                pageSize,
                region,
            );
            console.log('asdsadasd', unitId, region);
            console.log(JSON.stringify(response.data.data), 'response123');
            if (isResponseIsValid(response)) {
                //console.log(response.data.data, 'response');

                // setSpecialtiesList(response.data.data);
                if (currentPage == 1) {
                    setSpecialtiesList(response.data.data);
                    setNext_page(response.data.next_page);
                } else {
                    setSpecialtiesList([...specialtiesList, ...response.data.data]);
                    setNext_page(response.data.next_page);
                }

                setTimeout(() => {
                    setInitialLoader(false);
                    setLoader(false);
                }, 500);
            } else {
                setInitialLoader(false);
                setLoader(false);
                setTimeout(() => {
                    if (response?.data) {
                        snackBar(response?.data);
                    } else {
                        snackBar(Headers.apiError);
                    }
                }, 400);
            }
        } catch (err) {
            setInitialLoader(false);
            setLoader(false);
            setTimeout(() => {
                snackBar(Headers.apiError);
            }, 400);
        }
    };

    console.log('listtttt', specialtiesList);
    // Function to fetch the token
    const fetchToken = async () => {
        try {
            const tokenUrl = 'http://192.168.28.86/KAPP_INFOSHARE/token';

            const requestData = {
                grant_type: 'password',
                username: 'Kauvery',
                password: 'Kmc@123'
            };
            console.log('data :', requestData);
            const tokenResponse = await axios.post(tokenUrl, qs.stringify(requestData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log('url', tokenResponse);
            return tokenResponse.data.access_token;
        }
        catch (error) {
            console.error('Error fetching token:', error);
        }
    };

    // Function to fetch location data using the token
    const fetchLocations = async () => {

        try {
            // const tokenUrl = 'http://192.168.28.86/KAPP_INFOSHARE/api/Values/GetUnit';
            const tokenUrl = 'http://localhost:1801/api/unit';
            const locationResponse = await axios.get(tokenUrl, {
                headers: {
                    // 'Authorization': `Bearer ${token}`
                }
            });
            console.log('Response from POST API:', locationResponse.data.Result);
            setDropdownOptions(locationResponse.data.Result);

        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const onClose = () => {
        setOpenPopup(false);
    }

    const onDoctorClick = (doctor) => {
        console.log('rrrrrrrr', doctor);
        setOpenPopup(true);
        setDoctor(doctor);
    }

    const fetchDoctors = async (city1, spID) => {
        const city = city1;
        const spid = spID;
        // console.log('cityuuuuuuuuuuuuu', city);
        // console.log('idddddddddd', spID);
        try {
            const response = await axios.get(`http://localhost:1801/api/doctors/`, { params: { city, spid } });
            // Handle the response as needed
            console.log('Response from POST API:', response.data);
            setDoctors(response.data);
        } catch (error) {
            console.error('Error sending selected unit to API:', error);
        }
    };

    const sendSelectedUnitToAPI = async (city1) => {
        const city = city1;
        // console.log('cityuuuuuuuuuuuuu', city);
        try {
            const response = await axios.get(`http://localhost:1801/api/specialities/`, { params: { city } });
            // Handle the response as needed
            console.log('Response from POST API:', response.data);
            setSpecialities(response.data);
        } catch (error) {
            console.error('Error sending selected unit to API:', error);
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            // const fetchedToken = await fetchToken();
            // console.log('ksjhfoiudaogrequoIoj',fetchedToken);
            // setToken('ksdjfhwuetgsoe', fetchedToken);
            // if (fetchedToken) {
            fetchLocations();
            // sendSelectedUnitToAPI(selectedUnit);
            fetchDoctors(selectedUnit, spID);
            // }
        };

        initializeData();
    }, []);

    const handleSeeMore = () => {
        setShowMore(!showMore);
    };
    // console.log('uuuuuu', unitid)
    const handleBookinglist = () => {
        // history.push('/specialitydoctor');
        const queryParams = new URLSearchParams({
            dropdownOptions: dropdownOptions,
            selectedUnit: selectedUnit,
            unitAddress: unitAddress,
            unitid: unitId,
            city: city
        }).toString();

        history.push(`/specialitydoctor?${queryParams}`);
    };

    const [currentSlide, setCurrentSlide] = useState(0);



    //   const handleNextSlide = () => {
    //     setCurrentSlide((prev) => (prev + 1) % slides.length);
    //   };

    const handleMhclist = () => {
        // history.push({
        //     pathname: '/mhclist',
        //     state: { 
        //       dropdownOptions: dropdownOptions,
        //       selectedUnit: selectedUnit
        //     }
        //   });
        const queryParams = new URLSearchParams({
            dropdownOptions: dropdownOptions,
            selectedUnit: selectedUnit
        }).toString();

        history.push(`/mhclist?${queryParams}`);
    };

    const handleChange = (event) => {
        // console.log('inside');
        const selectedUnitName = event.target.value;
        setSelectedUnit(selectedUnitName);
        const selectedOption = dropdownOptions.find(option => option.UnitName === selectedUnitName);
        console.log('adrsssssssss', selectedOption);
        if (selectedOption) {
            setUnitAddress(selectedOption.Address);
            setUnitid(selectedOption.UnitId);
        }
        // sendSelectedUnitToAPI(event.target.value);
    };

    const handleProfile = () => {
        // console.log('inside');
        history.push('/profile');
    };

    const renderSpecialties = () => {
        if (showMoreSpecialties) {
            return specialtiesList.map((specialty, index) => (
                <div key={`facility-${index}`} style={styles.card}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = styles.specialtiesCardHover.boxShadow}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                >
                    <div style={styles.cardImageContainer}>
                        <img src={Images.generalMedicine} alt={specialty.name} style={styles.cardImage} />
                    </div>
                    <div style={styles.cardContent}>
                        <p style={{ fontFamily: 'Poppins', fontSize: '18px' }}>{specialty.department}</p>
                        <p style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{specialty.description}</p>
                    </div>

                </div>
            ));
        } else {
            return specialtiesList.slice(0, 5).map((specialty, index) => (
                <div key={`facility-${index}`} style={styles.card}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = styles.specialtiesCardHover.boxShadow}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                >
                    <div style={styles.cardImageContainer}>
                        <img src={Images.generalMedicine} alt={specialty.name} style={styles.cardImage} />
                    </div>
                    <div style={styles.cardContent}>
                        <p style={{ fontFamily: 'Poppins', fontSize: '18px' }}>{specialty.department}</p>
                        <p style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{specialty.description}</p>
                    </div>
                </div>
            ));
        }
    };

    const renderDoctors = () => {
        if (showMoreDoctors) {
            return doctorsDetails.map((doctor, index) => (
                <div key={`doctor-${index}`} style={styles.doctorCard}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = styles.specialtiesCardHover.boxShadow}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                >
                    <div style={styles.imageContainer}>
                        {doctor.photo ? (
                            <img src={doctor.photo} alt={doctor.name} style={styles.doctorImage} />
                        ) : (
                            <PersonIcon style={styles.doctorImage} htmlColor='#B0B0B0' />
                        )}
                    </div>
                    <div style={styles.doctorContent}>
                        <h2 style={{ fontFamily: 'Poppins', fontSize: '18px' }}>{doctor.first_name}</h2>
                        <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                            {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
                        </p>
                        {doctor.languages ? (
                            <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                                {Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages}
                            </p>) : (
                            <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>Tamil, English</p>
                        )}
                        <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>{doctor.default_specialization}</p>
                    </div>
                </div>
            ));
        } else {
            return doctorsDetails.slice(0, 5).map((doctor, index) => (
                <div key={`doctor-${index}`} style={styles.doctorCard}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = styles.specialtiesCardHover.boxShadow}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                >
                    <div style={styles.imageContainer}>
                        {doctor.photo ? (
                            <img src={doctor.photo} alt={doctor.name} style={styles.doctorImage} />
                        ) : (
                            <PersonIcon style={styles.doctorImage} htmlColor='#B0B0B0' />
                        )}
                    </div>
                    <div style={styles.doctorContent}>
                        <h2 style={{ fontFamily: 'Poppins', fontSize: '18px' }}>{doctor.first_name}</h2>
                        <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                            {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
                        </p>
                        {doctor.languages ? (
                            <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                                {Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages}
                            </p>) : (
                            <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>Tamil, English</p>
                        )}
                        <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>{doctor.default_specialization}</p>
                    </div>
                </div>
            ));
        }
    };

    const activeMenuStyle = (path) => {
        return location.pathname === path ? { color: COLORS.primaryColor } : { color: COLORS.placeholderColor };
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleLocationAgree = () => {
        try {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        nearbyUnitsWithCoordinates(
                            position.coords.latitude,
                            position.coords.longitude
                        );
                    },
                    error => console.log('Error occurred while fetching location: ', error.message),
                    { enableHighAccuracy: true, timeout: 20000 }
                );
                setLocationPopupOpen(false);
            } else {
                alert('Geolocation is not supported by your browser.');
            }
        } catch (err) {
            console.warn(err);
            alert('Something went wrong while accessing location.');
        }
    };

    const nearbyUnitsWithCoordinates = async (lat, long) => {
        setLoader(true);
        try {
            const response = await nearbyUnitsCoordinates(`${lat},${long}`);
            if (isResponseIsValid(response)) {
                setLocations(response.data.regions);
                setCity(response.data.current_region);
                dispatch(currentRegion(response.data.current_region));
                setUnitId(response.data.data[0]._id.$oid);
                setLoader(false);
            } else {
                setLoader(false);
                snackBar(response?.data || Headers.apiError);
            }
        } catch (err) {
            setLoader(false);
            snackBar(Headers.apiError);
        }
    };

    const handleLocationDisagree = () => {
        localStorage.setItem('hasSeenLocationPopup', 'true');
        // setCity('');
        setLocationPopupOpen(false);
    };
    const handleEvents = () => {
        // return (
        //     <Box>
        //         <ConsultationCard
        //             type="video"
        //             date="Saturday, Sep 9th"
        //             time="11 AM"
        //             doctor="Shridhar"
        //             patient="Bharathi"
        //             status="active"
        //             fee="1000"
        //             tab='0'
        //             booking_id="b6fa7bb5-94ee-4e90-8eb1-ef11e89fe02b"
        //         />
        //         <ConsultationCard
        //             type="hospital"
        //             date="Saturday, Sep 9th"
        //             time="2 PM"
        //             doctor="Shridhar"
        //             patient="Akalya"
        //             location="Chennai - Mylapore"
        //             status="cancelled"
        //             tab='0'
        //         />
        //         <ConsultationCard
        //             type="hospital"
        //             date="Saturday, Sep 9th"
        //             time="2 PM"
        //             doctor="Shridhar"
        //             patient="Akalya"
        //             location="Chennai - Mylapore"
        //             status="active"
        //             fee="1000"
        //             tab='0'
        //         />
        //     </Box>
        // );
        return (
            <Box>
                {displayedEvent.map((event, index) => (
                    <ConsultationCard
                        key={event.booking_id}
                        type={event.slot_details[0].slot_type.toLowerCase() === "offline" ? "hospital" : "video"}
                        date={new Date(event.slot_details[0].slot_date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}
                        time={event.slot_details[0].slot_start_time}
                        doctor={event.slot_details[0].doctor_name}
                        patient={event.patient_name}
                        location={event.slot_details[0].unit_details.unit_name}
                        status={event.ui_booking_status.toLowerCase()}
                        fee={event.payment_status === "pending" ? "1000" : null}
                        tab='0'
                        booking_id={event.booking_id}
                        event={event}
                        paymentStatus={event.payment_status}
                    />
                ))}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        sx={{
                            mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                            },
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                            ':active': {
                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                            },
                        }}
                        onClick={() => setShowAll2(!showAll2)}
                    >
                        {showAll2 ? 'View Less' : 'View all'}
                    </Button>
                </Box>
            </Box>
        );
    }
    const handleConsultations = () => {
        return (
            <Box>
                <ConsultationCard
                    type="video"
                    date="Saturday, Sep 9th"
                    time="11 AM"
                    doctor="Shridhar"
                    patient="Bharathi"
                    status="active"
                    fee="1000"
                    tab='1'
                />
                <ConsultationCard
                    type="hospital"
                    date="Saturday, Sep 9th"
                    time="2 PM"
                    doctor="Shridhar"
                    patient="Akalya"
                    location="Chennai - Mylapore"
                    status="cancelled"
                    tab='1'
                />
                <ConsultationCard
                    type="hospital"
                    date="Saturday, Sep 9th"
                    time="2 PM"
                    doctor="Shridhar"
                    patient="Akalya"
                    location="Chennai - Mylapore"
                    status="active"
                    fee="1000"
                    tab='1'
                />
            </Box>
        );
    };

    const handleSpecialtyClick = async (specialtyId, unitId, spec_name, specialty) => {
        // setActiveSpecialty(specialtyId);
        const queryParams = new URLSearchParams({
            dropdownOptions: dropdownOptions,
            selectedUnit: selectedUnit,
            unitAddress: unitAddress,
            unitid: unitId,
            city: city,
            spId: specialtyId,
            specName: spec_name
        }).toString();

        history.push(`/doctors?${queryParams}`);
    };

    const handleSlots = (selectedUnit, item) => {
        // history.push({
        //     pathname: '/appointmentbook',
        //     state: { selectedUnit, item },
        //   });
        const queryParams = new URLSearchParams({
            selectedUnit: selectedUnit,
            item: JSON.stringify(item),
            dropdownOptions: dropdownOptions,
            unitAddress: unitAddress,
            unitid: unitId
            // item: item,
        }).toString();

        history.push(`/appointmentbook?${queryParams}`);
    };

    const handleReq = (selectedUnit, item) => {
        // history.push({
        //     pathname: '/appointmentbook',
        //     state: { selectedUnit, item },
        //   });
        const queryParams = new URLSearchParams({
            selectedUnit: selectedUnit,
            item: JSON.stringify(item),
            dropdownOptions: dropdownOptions,
            unitAddress: unitAddress,
            unitid: unitId
            // item: item,
        }).toString();

        history.push(`/request?${queryParams}`);
    };


    return (
        <Container maxWidth="xl" style={styles.root}>
            {/* Header */}
            {/* <LocationPopup
                open={locationPopupOpen}
                onClose={handleLocationDisagree}
                onAgree={handleLocationAgree}
            /> */}
            {/* <AppBar position="static" style={styles.header}>
                <Toolbar style={styles.toolbar}>
                    <img src={Images.kauveryLogo} alt="Logo" style={styles.logo} />
                    <Box style={styles.locationSection}>
                        <LocationOnIcon />
                        <Select
                            value={selectedUnit}
                            onChange={handleChange}  // Use onChange instead of onClick
                            displayEmpty  // Keeps the empty option visible
                            inputProps={{ 'aria-label': 'Select location' }}
                            IconComponent={ArrowDropDownIcon}
                            sx={{ minWidth: '200px', maxWidth: '100%', ...styles.dropdown }}  // Adjust width as needed
                            variant="outlined"
                        >
                            <MenuItem disabled value="">
                                {selectedUnit}
                            </MenuItem>
                            {dropdownOptions.map(option => (
                                <MenuItem key={option.id} value={option.UnitName}>
                                    {option.UnitName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <IconButton color="inherit">
                        <FontAwesomeIcon icon={faBell} style={{ color: '#962067' }} />
                    </IconButton>
                </Toolbar>
            </AppBar> */}
            <Header
                dropdownOptions={dropdownOptions}
                selectedUnit={selectedUnit}
                unitAddress={unitAddress}
                unitid={unitId}
                city={city}
            />

            {/* Second Split */}
            {/* <Box style={styles.secondSplit}>
                <Toolbar style={styles.toolbar1}>
                    <Box display="flex" alignItems="center"> */}
            {/* <Button className={`${styles.button} active`}>
            <Box style={styles.menuItem}>
              <HomeIcon style={styles.menuIcon} />
              <Typography style={styles.menuText}>Hospital visit</Typography>
            </Box>
          </Button>
          <Button className={styles.button}>
            <Box style={styles.menuItem}>
              <NoteIcon style={styles.menuIcon} />
              <Typography style={styles.menuText}>Hospital records</Typography>
            </Box>
          </Button> */}
            {/* <Button
                            className={styles.button}
                            style={activeMenuStyle('/')}
                            onClick={() => history.push('/')}
                            disabled={!name}
                        >
                            <Box style={styles.menuItem}>
                                <IconButton>
                                    <HomeIcon style={styles.menuIcon} />
                                    <Typography style={styles.menuText}>Home</Typography>
                                </IconButton>
                            </Box>
                        </Button>
                        <Button
                            className={styles.button}
                            style={activeMenuStyle('/specialitydoctor')}
                            onClick={() => history.push('/specialitydoctor')}
                        // disabled={!name}
                        >
                            <Box style={styles.menuItem}>
                                <StethoscopeIcon style={styles.menuIcon} />
                                <Typography style={styles.menuText}>Consult</Typography>
                            </Box>
                        </Button>
                        <Button
                            className={styles.button}
                            style={activeMenuStyle('/mhclist')}
                            onClick={handleMhclist}
                        // disabled={!name}
                        >
                            <Box style={styles.menuItem}>
                                <BookIcon style={styles.menuIcon} />
                                <Typography style={styles.menuText}>MHC</Typography>
                            </Box>
                        </Button>
                        <Button
                            className={styles.button}
                            style={activeMenuStyle('/records')}
                            onClick={() => history.push('/records')}
                            disabled={!name}
                        >
                            <Box style={styles.menuItem}>
                                <NoteIcon style={styles.menuIcon} />
                                <Typography style={styles.menuText}>Records</Typography>
                            </Box>
                        </Button> */}
            {/* <LoginDrawer open={isDrawerOpen} onClose={handleDrawerClose} />
                    </Box> */}
            {/* <Button className={styles.userButton}>
                        <Typography style={styles.userButton}>{name}</Typography>
                        <Box style={styles.exicon}>
                            <ExpandMoreIcon style={{ color: '#962067' }} />
                        </Box>
                    </Button> */}
            {/* {name ? (
                        <div>
                            <Button className={styles.userButton} onClick={handleClick}>
                                <Typography style={styles.userButton}>{name}</Typography>
                                <Box style={styles.exicon}>
                                    <ExpandMoreIcon style={{ color: '#962067' }} />
                                </Box>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                <MenuItem onClick={handleClose}>Sign out</MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <Button className={styles.userButton} onClick={handleDrawerOpen}>
                            <Typography style={styles.userButton}>Login</Typography>
                        </Button>
                    )}
                </Toolbar> */}
            {/* <Box style={styles.imageSection1}>
                    <Box style={styles.instructions}>
                        <Typography variant="body1" style={styles.ins1}>Welcome to Kauvery Kare!</Typography>
                        <br />
                        <br />
                        <Typography variant="body1" style={styles.ins2}>Hey there, Kauvery cares.</Typography>
                        <br />
                        <Typography variant="body1" style={styles.ins3}>Your Healthcare, just a click away!</Typography>
                    </Box>

                    <img src={Images.family_front} alt="img" style={styles.rightimg} />
                </Box>

                <Box style={styles.searchBar}  >
                    <Button onClick={handleBookinglist} style={styles.searchButton} startIcon={<img src={Images.appointment} alt="Appointment" />}>Book an Appointment</Button>
                    <Button onClick={handleMhclist} style={styles.searchButton} startIcon={<img src={Images.healthCheckup} alt="Health checkup" />}>Book Health checkups</Button>
                </Box>


                <Box style={styles.imageSection}>
                    <Typography variant="body1" style={styles.buttext}>
                        Book for In-hospital or Video Consultation
                    </Typography>
                    <Typography variant="body1" style={styles.buttext1}>
                        Book online to avail offers
                    </Typography>
                </Box>
                <br />

                <Box style={styles.searchBar1}>
                    {/* <Box style={{display: 'flex', flexDirection: 'column'}}> */}
            {/* <img src={Images.qr} alt="Right" style={styles.qrimage} />

                    <Typography variant="body1" style={styles.qrtext}>
                        Download our Mobile app
                    </Typography>
                    {/* </Box> */}
            {/* </Box> */}

            {/* <Typography variant="body1" style={styles.serachtext}>
                    What service can we help you find?
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '-80px' }}>
                    {/* <div style={styles.search}> */}
            {/* <Box style={{ marginLeft: '650px', width: '50%', marginBottom: '20px' }}>
                        <input type="search" style={styles.searchfield} placeholder="Search by Specialty, Condition or Doctor Name" />
                    </Box> */}
            {/* <img src={Images.searchIcon} alt="Right" style={styles.searchIcon} /> */}
            {/* <Button style={styles.searchmain}>Search</Button> */}
            {/* </div> */}
            {/* </Box>  */}

            {/* </Box>  */}

            <Box sx={{ display: 'flex', padding: '20px', alignItems: 'center', backgroundColor: '#FFEEF6' }}>
                {/* <LoginBox /> */}
                {!name && (
                <Box
                    sx={{
                        // position: 'absolute',
                        // right: '20px',
                        // top: '30%',
                        // transform: 'translateY(-50%)',
                        // backgroundColor: COLORS.primaryColor,
                        // borderRadius: '8px',
                        // padding: '10px',
                        // display: 'flex',
                        // alignItems: 'center',
                        // flexDirection: 'column',
                        // color: '#fff',
                        // textAlign: 'center',
                        // width: '3%'
                        position: 'fixed',
                        right: 0,
                        top: '30%',
                        transform: 'translateY(-50%)',
                        backgroundColor: COLORS.primaryColor,
                        borderRadius: '30px 0 0 30px', // Rounded corners on the left side
                        padding: '10px 0', // Padding for top and bottom
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        color: '#fff',
                        textAlign: 'center',
                        width: '50px', // Adjust width based on content
                        height: '150px', // Adjust height based on content
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={() => setIsDrawerOpen(true)}
                        sx={{
                            ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                            },
                        }}
                    >

                        <Typography variant="body2"
                            component="div"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: '#ffffff',
                                fontFamily: 'Poppins',
                                alignItems: 'center',
                                marginBottom: '8px',
                                fontSize: '16px', // Adjust font size as needed
                                letterSpacing: '2px', // Adjust spacing between letters>
                            }}
                        >
                            {'LOGIN'.split('').map((char, index) => (
                                <Box key={index} sx={{ lineHeight: '16px' }}>
                                    {char}
                                </Box>
                            ))}
                        </Typography>
                        {/* <Button
        variant="contained"
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#9C27B0',
          fontSize: '12px',
          padding: '6px 12px',
          borderRadius: '16px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        Login
      </Button> */}
                    </Button>
                    <LoginIcon sx={{ fontSize: '22px', transform: 'rotate(270deg)', zIndex: 1000, marginBottom: '8px', backgroundColor: '#ffffff', color: COLORS.primaryColor }} />
                </Box>
                )}
                {/* Left Side Content */}
                <Box sx={{ width: '45%', textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ color: COLORS.primaryColor, marginBottom: '12px', fontFamily: 'Poppins' }}>Welcome to Kauvery Kare!</Typography>
                    <Typography variant="h6" sx={{ marginBottom: '14px', fontFamily: 'Poppins', color: COLORS.textColor }}>Hey there, Kauvery cares.</Typography>
                    <Typography variant="body1" sx={{ color: COLORS.placeholderColor, marginBottom: '32px', fontFamily: 'Poppins', fontSize: '14px' }}>
                        Your Healthcare, just a click away!
                    </Typography>

                    <Box sx={{ marginBottom: '32px', display: 'flex', justifyContent: 'center', height: '5%' }}>
                        <Button variant="contained" startIcon={<img src={Images.appointment} alt="Appointment" />} sx={{
                            backgroundColor: '#ffffff', color: '#000', marginRight: '16px', textTransform: 'none',
                            boxShadow: '0px 3px 6px #00000029',
                            border: '1px solid #E9AFB7',
                            borderRadius: '35px',
                            // outline: COLORS.primaryColor,
                            '&:hover': {
                                backgroundColor: '#ffffff',
                            },
                            ':focus': {
                                outline: COLORS.primaryColor,
                                boxShadow: `0 0 0 2px #ffffff`, // Change this to `transparent` if you want no color
                            },
                            ':active': {
                                boxShadow: `0 0 0 2px #ffffff`, // Keeps the focus ring on active state
                            },
                        }}
                            onClick={handleBookinglist}
                        >

                            <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '14px', }}>Book an Appointment</Typography>
                        </Button>
                        <Button variant="contained" startIcon={<img src={Images.healthCheckup} alt="Health checkup" />} sx={{
                            backgroundColor: '#ffffff', color: '#000', textTransform: 'none',
                            boxShadow: '0px 3px 6px #00000029',
                            border: '1px solid #E9AFB7',
                            borderRadius: '35px',
                            '&:hover': {
                                backgroundColor: '#ffffff',
                            },
                            ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                            },
                            ':active': {
                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                            },
                        }}>

                            <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '14px' }}>Book Health checkups</Typography>
                        </Button>
                    </Box>

                    <Box sx={{ marginBottom: '32px' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}>
                            What service can we help you find?
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <input type="text" placeholder="Search by Specialty, Condition or Doctor Name" style={{
                                padding: '8px', border: '1px solid #ccc', width: '60%', fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px',
                                border: '1px solid #962067',
                                borderRadius: '35px',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                },
                                ':focus': {
                                    outline: '#962067',
                                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                                },
                                ':active': {
                                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                },
                            }} />
                            <Button variant="contained" sx={{
                                backgroundColor: COLORS.primaryColor, color: '#fff', marginLeft: '8px', fontFamily: 'Poppins', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: COLORS.primaryColor,
                                },
                                ':focus': {
                                    outline: 'none',
                                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                                },
                                ':active': {
                                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                },
                            }}>Search</Button>
                        </Box>
                    </Box>

                    <Box>
                        <img src={Images.qr} alt="QR Code" style={{ marginBottom: '8px', width: '80px', height: '80px' }} />
                        <Typography variant="body2" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', color: COLORS.primaryColor }} >Download our IOS & Android Mobile app</Typography>
                    </Box>
                </Box>

                {/* Right Side Image Slider */}
                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    {slides[currentSlide].heading ? (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Image Container */}
                            <Box
                                sx={{
                                    width: '40%',   // Adjust the width for the desired size of the circle
                                    height: '35%',  // Ensures it's a square to maintain the circle shape
                                    backgroundColor: '#fff', // White background
                                    borderRadius: '50%', // Makes the box circular
                                    overflow: 'hidden', // Ensures the image is clipped inside the circle
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 4px 6px rgba(255, 105, 180, 0.3)', // Optional: Adds a subtle shadow for a 3D effect
                                    mb: 2, // Margin bottom to separate from the text
                                }}
                            >
                                <img
                                    src={slides[currentSlide].image}
                                    alt={`Slide ${currentSlide}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain', // Ensures the image fits the circular container
                                        borderRadius: '50%', // This also ensures the image takes the circular shape
                                    }}
                                />
                            </Box>

                            {/* Headings Container */}
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: COLORS.primaryColor,
                                        // backgroundColor: '#fff',
                                        padding: '10px 20px',
                                        borderRadius: '8px',
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        // mb: 1,
                                    }}
                                >
                                    {slides[currentSlide].heading}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: COLORS.textColor,
                                        // backgroundColor: '#fff',
                                        padding: '5px 20px',
                                        borderRadius: '8px',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        mb: 3,
                                    }}
                                >
                                    {slides[currentSlide].subheading}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: '400px',
                                position: 'relative', // Allows absolute positioning of text inside
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={slides[currentSlide].image}
                                alt={`Slide ${currentSlide}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover', // Ensures the image covers the entire Box
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>
                    )}

                    {/* Navigation Dots */}
                    <Box sx={{ position: 'absolute', bottom: '16px', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center' }}>
                        {slides.map((_, index) => (
                            <Box
                                key={index}
                                onClick={() => handleDotClick(index)}
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: index === currentSlide ? COLORS.primaryColor : '#ccc',
                                    margin: '0 5px',
                                    cursor: 'pointer',
                                }}
                            />
                        ))}
                    </Box>

                    {/* Right Arrow */}
                    <IconButton
                        onClick={handleNextSlide}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '-20px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#fff',
                            '&:hover': { backgroundColor: '#eee' },
                            '&:active': { backgroundColor: '#eee', outline: '#ffffff' },
                            '&:focus': { backgroundColor: '#eee', outline: '#ffffff' },
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>


            </Box>

            <Box style={styles.thirdSplit}>
                {!name ? (
                    <Box style={styles.instructions}>
                        <Typography variant="body1" sx={{ fontFamily: 'Poppins', paddingTop: '10px', fontSize: '24px', color: '#962067', display: 'flex', textAlign: 'center', justifyContent: 'center', mb: 2 }}>Upcoming events</Typography>
                        <Box sx={{ backgroundColor: '#FFEEF6', paddingTop: '0px', justifyContent: 'center', width: '55%', ml: 30, borderRadius: '10px' }}>
                            <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLORS.textColor }}>No events! Please login to your account to stay updated on your upcoming events</Typography>
                        </Box>
                        {/* <br />
                    <Typography variant="body1" style={styles.tt3}>Please login to your account to stay updated on your upcoming events</Typography>
                    <img src={Images.eventCalander} alt="Right" style={styles.eventCalander} /> */}
                    </Box>
                ) : (
                    <Box sx={{ padding: '0px', width: '95%' }}>
                        <Tabs value={tab} onChange={handleTabChange} centered sx={{ mb: 2 }} TabIndicatorProps={{ style: { display: 'none' } }} >
                            <Tab label="Upcoming events"
                                icon={<EventIcon />}
                                iconPosition="start"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    color: tab === 0 ? COLORS.whiteColor : COLORS.textColor,
                                    backgroundColor: tab === 0 ? COLORS.primaryColor : 'transparent',
                                    width: '50%',
                                    minHeight: '10%',
                                    height: '10%',
                                    borderBottom: 'none',// Adjust width to fit the container
                                    '&:hover': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#ffffff',
                                        borderColor: COLORS.primaryColor
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#ffffff',
                                        borderBottom: 'none',// Ensures white text when active (selected)
                                    },
                                    // '&:active': {
                                    //   backgroundColor: COLORS.primaryColor,
                                    //   color: selectedTab === 0 ? '#ffffff' : COLORS.textColor,
                                    //   borderColor: COLORS.primaryColor
                                    // },
                                    '&:active': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#ffffff', // Ensures white text on active state
                                        borderColor: COLORS.primaryColor,
                                        borderBottom: 'none',
                                    },
                                }}
                            />
                            <Tab label="Past consultations"
                                icon={<RestoreIcon />}
                                iconPosition="start"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Poppins',
                                    color: tab === 1 ? '#ffffff' : COLORS.textColor,
                                    backgroundColor: tab === 1 ? COLORS.primaryColor : 'transparent',
                                    width: '100%', // Adjust width to fit the contain
                                    minHeight: '10%',
                                    height: '10%',
                                    '&:hover': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#ffffff',
                                        borderColor: COLORS.primaryColor
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: COLORS.primaryColor,
                                        color: '#ffffff',
                                        borderBottom: 'none',// Ensures white text when active (selected)
                                    },
                                }}
                            />
                        </Tabs>
                        {tab === 0 && handleEvents()}
                        {tab === 1 && handleConsultations()}
                    </Box>
                )}
            </Box>

            {/* Fourth Split Features */}
            {/* <Box style={styles.fourthSplit}> */}
            {/* <div style={styles.featuresContainer}>
                    <h2 style={styles.featuresTitle}>Our Features</h2>
                    <div style={styles.featuresList}>
                        <div style={styles.featureItem}>
                            <div style={styles.featureIcon}>
                                <img
                                    style={styles.featureImage}
                                    src={Images.hospitalVisit}
                                    alt="Book Consultation"
                                />
                            </div>
                            <h3 style={styles.featureTitle}>Book Consultation</h3>
                            <p style={styles.featureDescription}>
                                Book an online consultation or hospital visit with our in-house specialties.
                            </p>
                        </div>
                        <div style={styles.featureItem}>
                            <div style={styles.featureIcon}>
                                <img
                                    style={styles.featureImage}
                                    src={Images.scientist}
                                    alt="Book a Master Health Check Up"
                                />
                            </div>
                            <h3 style={styles.featureTitle}>Book a Master Health Check Up</h3>
                            <p style={styles.featureDescription}>
                                Our access to the wide range of lab tests available.
                            </p>
                        </div>
                        <div style={styles.featureItem}>
                            <div style={styles.featureIcon}>
                                <img
                                    style={styles.featureImage}
                                    src={Images.familyMember}
                                    alt="Get access to Health Records"
                                />
                            </div>
                            <h3 style={styles.featureTitle}>Get access to Health Records</h3>
                            <p style={styles.featureDescription}>
                                Book an online consultation or hospital visit with our in-house specialties.
                            </p>
                        </div>
                        <div style={styles.featureItem}>
                            <div style={styles.featureIcon}>
                                <img
                                    style={styles.featureImage}
                                    src={Images.familyMember}
                                    alt="Add Your Family Member"
                                />
                            </div>
                            <h3 style={styles.featureTitle}>Add Your Family Member</h3>
                            <p style={styles.featureDescription}>
                                Add your family members to the account and keep track.
                            </p>
                        </div>
                    </div>
                </div> */}
            {/* </Box> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'Column',
                    width: '98%',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '10px',
                    padding: '20px',
                    mt: 1,
                    ml: 1,
                    mb: 1,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 1 }}>
                    {/* <LocationOnIcon sx={{}} /> */}
                    <Select
                        value={selectedUnit}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select location' }}
                        IconComponent={ArrowDropDownIcon}
                        input={
                            <OutlinedInput
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LocationOnIcon sx={{ color: COLORS.primaryColor, fontSize: '16px' }} />
                                    </InputAdornment>
                                }
                                sx={{
                                    height: '25px',
                                    fontFamily: 'Poppins',
                                    color: COLORS.textColor,
                                    fontSize: '13px',
                                    alignItems: 'center',
                                    borderRadius: '9px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Corrected syntax for boxShadow
                                    },
                                }}
                            />
                        }
                    >
                        <MenuItem disabled value="" sx={{ fontFamily: 'Poppins', fontSize: '13px', }}>
                            {selectedUnit}
                        </MenuItem>
                        {dropdownOptions.map(option => (
                            <MenuItem key={option.id} value={option.UnitName} sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '13px', }}>
                                {option.UnitName}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Grid container spacing={4} sx={{ mt: 2 }}>
                    {/* Featured Specialty Areas */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}>
                                Specialties
                            </Typography>
                        </Box>
                        {/* <Grid container spacing={2} sx={{ marginBottom: 2 }}> */}
                        {displayedSpecialties.map((specialty, index) => (
                            // <Grid item xs={12} md={6} key={index}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                    marginBottom: '16px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '7px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                    '&:hover': {
                                        boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                                    },
                                }}
                                onClick={() => handleSpecialtyClick(specialty._id.$oid, unitId, specialty.department)}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={Images.generalMedicine} sx={{ marginRight: 2 }}>
                                        {/* <LocalHospitalIcon /> */}
                                    </Avatar>

                                    {/* <CardContent sx={{ padding: 0 }}> */}
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                                        >
                                            {specialty.department}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: COLORS.placeholderColor, fontFamily: 'Poppins' }}
                                        >
                                            {specialty.description}
                                        </Typography>
                                    </Box>
                                    {/* </CardContent> */}
                                </Box>
                            </Box>
                            // </Grid>
                        ))}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                                        outline: 'none',
                                        boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                    },
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                    },
                                }}
                                onClick={() => setShowAll(!showAll)}
                            >
                                {showAll ? 'View Less' : 'View all'}
                            </Button>
                        </Box>
                        {/* </Grid> */}
                    </Grid>

                    {/* Top Doctors */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}>
                                Doctors
                            </Typography>
                            {/* <Button
                                sx={{
                                    mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                                        outline: 'none',
                                        boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                    },
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                    },
                                }}
                                onClick={() => setShowAll(!showAll)}
                            >
                                {showAll ? 'View Less' : 'View all'}
                            </Button> */}
                        </Box>
                        {displayedDoctors.map((doctor, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '8px',
                                    marginBottom: '16px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '7px',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                    '&:hover': {
                                        boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                                    },
                                }}
                                onClick={() => onDoctorClick(doctor)}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        alt={doctor.name}
                                        src={doctor.photo}
                                        sx={{ marginRight: 2, width: 85, height: 90 }}
                                    />
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                                        >
                                            {doctor.first_name}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                                        >
                                            {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: COLORS.textColor, fontFamily: 'Poppins', fontSize: '11px' }}
                                        >
                                            {doctor.default_specialization}
                                        </Typography>
                                        {/* <Typography variant="body2" sx={{ color: '#777' }}>
                                            {doctor.spe}
                                        </Typography> */}
                                        <Typography
                                            variant="caption"
                                            sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                                        >
                                            Language: {doctor.languages ?
                                                (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                                                'Tamil, English'
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                                <Button
                                    onClick={() => {
                                        if (doctor.request_only === 0) {
                                            handleSlots(selectedUnit, doctor);
                                        } else {
                                            handleReq(selectedUnit, doctor);
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        minWidth: '100px',
                                        fontSize: '12px',
                                        padding: '1px 14px',
                                        textTransform: 'none',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '15px',
                                        '&:hover': {
                                            backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
                                        },
                                        ':focus': {
                                            outline: 'none',
                                            boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                                        },
                                        // ':hover': {
                                        //   backgroundColor: COLORS.primaryColor,
                                        // },
                                        ':active': {
                                            boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                        },
                                    }}
                                >
                                    {doctor.request_only === 0 ? 'Book Now' : 'Request'}
                                </Button>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                                        outline: 'none',
                                        boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                                    },
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    ':active': {
                                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                                    },
                                }}
                                onClick={() => setShowAll1(!showAll1)}
                            >
                                {showAll1 ? 'View Less' : 'View all'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            {/* <div style={styles.container}> */}
            {/* Left side (Specialties) */}
            {/* <div style={styles.section}>
                    <p style={styles.title}>Specialties
                        {specialtiesList.length > 5 && ( */}
            {/* <Button onClick={handleSeeMoreSpecialties} style={styles.see}> */}
            {/* <span */}
            {/* style={styles.see}
                                onClick={handleSeeMoreSpecialties} */}
            {/* > */}
            {/* {showMoreSpecialties ? 'See Less' : 'See All'} */}
            {/* </span> */}
            {/* </Button> */}
            {/* )} */}
            {/* </p> */}
            {/* {renderSpecialties()} */}
            {/* </div> */}

            {/* Right side (Doctors) */}
            {/* <div style={styles.section}> */}
            {/* <p style={styles.title}>Doctors */}
            {/* {doctorsDetails.length > 5 && ( */}
            {/* <Button onClick={handleSeeMoreSpecialties} style={styles.see}> */}
            {/* <span */}
            {/* style={styles.see}
                                onClick={handleSeeMoreDoctors}
                            > */}
            {/* {showMoreDoctors ? 'See Less' : 'See All'}
                            </span> */}
            {/* // </Button> */}
            {/* )} */}
            {/* </p> */}
            {/* {renderDoctors()} */}
            {/* </div> */}
            {/* </div> */}
            <VideoComponent />
            {/* Footer */}
            <Box style={styles.footer}>
                <Typography variant="body2">
                    © 2024 Patient Appointment Booking. All rights reserved.
                </Typography>
            </Box>
            <LoginDrawer open={isDrawerOpen} onClose={handleDrawerClose} />
            <Modal open={openPopup} onClose={onClose} aria-labelledby="doctor-popup" aria-describedby="doctor-details">

                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', sm: '70%', md: '70%' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none',
                    maxHeight: '80%',
                    overflowY: 'auto'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <Typography variant="h6" id="doctor-popup" sx={{ color: COLORS.primaryColor, fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '16px' }}>
                            About the doctor
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon color={COLORS.primaryColor} />
                        </IconButton>
                    </Box>
                    {doctor ? (
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    {/* <Avatar src={doctor[0].photo} alt={doctor.first_name} sx={{ width: 60, height: 60, mr: 2 }} />
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{doctor[0].first_name}</Typography> */}
                                    {/* <Typography variant="body2" color="textSecondary">{doctor && doctor.designation.join(', ')}</Typography> */}
                                    {/* </Box> */}

                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)',}}> */}
                                    <Avatar
                                        src={doctor.photo ? doctor.photo : ''}
                                        alt="Doctor Image"
                                        sx={{ width: 50, height: 55, marginRight: 2, ml: 1, mt: 1, }}
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '15px' }}>{doctor.first_name}</Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}</Typography>
                                    </Box>
                                    <Box sx={{ ml: 8 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{doctor.default_specialization}</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                    <Box sx={{ ml: 12 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{doctor.languages ?
                                            (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                                            'Tamil, English'
                                        }</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                    {/* <Typography variant="body2">{doctor.default_specialization}</Typography> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                        {/* <Typography variant="body2">{doctor.available_time.start_time && doctor.available_time.start_time}-{doctor.available_time.end_time && doctor.available_time.end_time}</Typography> */}
                                    </Box>
                                </Box>

                                <Typography variant="body2" sx={{ mt: 2, color: COLORS.textColor, fontFamily: 'Poppins', textAlign: 'justify' }}>
                                    {doctor.description}
                                </Typography>
                            </Box>
                        </Box>
                    ) : ('')}
                </Box>
            </Modal>
            <Dialog open={locationPopupOpen} onClose={handleLocationDisagree}>
                <DialogTitle sx={{ fontFamily: 'Poppins' }}>Select Location</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Select
                            value={selectedUnit}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select location' }}
                            IconComponent={ArrowDropDownIcon}
                            input={
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LocationOnIcon sx={{ color: COLORS.primaryColor, fontSize: '16px' }} />
                                        </InputAdornment>
                                    }
                                    sx={{
                                        height: '25px',
                                        fontFamily: 'Poppins',
                                        color: COLORS.textColor,
                                        fontSize: '13px',
                                        alignItems: 'center',
                                        borderRadius: '9px',
                                        ':hover': {
                                            backgroundColor: 'transparent',
                                        },
                                        ':active': {
                                            boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Corrected syntax for boxShadow
                                        },
                                    }}
                                />
                            }
                        >
                            <MenuItem disabled value="" sx={{ fontFamily: 'Poppins', fontSize: '13px', }}>
                                {selectedUnit}
                            </MenuItem>
                            {dropdownOptions.map(option => (
                                <MenuItem key={option.id} value={option.UnitName} sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '13px', }}>
                                    {option.UnitName}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleLocationDisagree} color="primary">No</Button>
                    <Button onClick={handleLocationAgree} color="primary" autoFocus>Yes</Button>
                </DialogActions> */}
            </Dialog>
        </Container >
    );
};

export default Home;