import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  IconButton,
  Button,
  Avatar,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import {
  bookingInitialize,
  createBooking,
  createBookingPayAtHospital,
  createWebHooks,
} from '../utils/apiCalls';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { familyMembersList, requestNow } from '../utils/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { patientDetails, patient_Id, patient_Name, patient_Notes } from '../store/actions/bookingActions';
import { UploadDuplicateBookingList, UploadFileBookingList, emptyBookingFileUploadedFile, removeBookingFileUploadedFile } from '../store/actions/uploadBookingActions';
import { KauvaryFileUpload } from '../utils/KauveryFileUpload';
import { EventEmitter } from 'events';
import { getSecondaryProfileID, getUserInformation, setPayCheckoutSuccess, setWebHookCheckout } from '../utils/LocalStorage';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { Base64 } from 'js-base64';
import { currentProfileRawData, familyReloadData } from '../store/actions/homeActions';
import { emptyBookingUploadRecord, emptyUploadRecord, myUploadSelectedClear, myUploadBookingSelectedList, onMyUploadBookingSelection } from '../store/actions/recordActions';
import axios from 'axios';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { formatDateTime } from '../utils/helpers';
import CancelIcon from '@mui/icons-material/Cancel';
import {reScheduleBooking, updateReschedule} from '../utils/apiCalls';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledTextField = styled(TextField)({
  // marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-root.Mui-focused': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
});

const StyledSelect = styled((props) => <TextField select {...props} />)({
  marginTop: '20px',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
  '& .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on focus
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove border color on hover
  },
  '& .MuiSelect-icon': {
    color: COLORS.primaryColor, // Custom color for dropdown icon
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: COLORS.placeholderColor, // Black color for focused label
  },
  '& .Mui-focused .MuiInputBase-input': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .Mui-focused .MuiInputBase-root': {
    fontFamily: 'Poppins, sans-serif',
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    color: COLORS.placeholderColor, // Black color for placeholder
  },
});

const eventEmitter = new EventEmitter();

const SelectPatient = () => {
  // const classes = useStyles;
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedSlot, setSelectedSlot] = useState(null);
  const [open, setOpen] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCheckoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState(generateDateRange(new Date()));
  // const [dropdownOptions, setDropdownOptions] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [relationData, setRelationData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [userToken, setUserToken] = useState('');
  const [nameErrorState, SetNameErrorState] = useState(false);
  const [notesErrorState, SetNotesErrorState] = useState(false);
  const [name, setName] = useState('');
  const [nameErrorText, SetNameErrorText] = useState('');
  const [signUpState, setSignUpState] = useState(false);
  const [relationValue, setRelationValue] = useState('');
  const [notes, setNotes] = useState('');
  const [slotid, setSlotid] = useState(1687304);
  const [bookingErrorContent, setBookingErrorContent] = useState('');
  const [uhidList, setUhidList] = useState([]);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [isPatientNameValid, setIsPatientNameValid] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [dataList, setDataList] = useState({});
  const [rescheduleErrorContent, setRescheduleErrorContent] = useState('');

  const [isFormFilled, setIsFormFilled] = useState(true);
  const [formData, setFormData] = useState({
    relationValue: '',
    notes: '',
    uploadedFile: null,
    amount: 0, // Assuming amount is part of the form
  });

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [patientId, setPaitentId] = useState('');
  const unitName = useSelector(
    state => state?.authReducer?.unitName,
  );

  const upcomingListPage = useSelector(
    state => state?.homeReducer?.upcomingListPageShown,
  );

  const slotDetails = useSelector(
    // Slot details                                     
    state => state?.bookingReducer?.slotDetails,
  );

  const slotStartTime = useSelector(
    // Slot Start time
    state => state?.bookingReducer?.slotStartTime,
  );

  const slotAddress = useSelector(
    // Slot address
    state => state?.bookingReducer?.slotAddress,
  );

  const amount = useSelector(
    // Amount
    state => state?.bookingReducer?.amount,
  )

  const patientDetails = useSelector(
    // Patient details
    state => state?.bookingReducer?.patientDetails,
  );

  const doctorDetails = useSelector(
    // Doctor details
    state => state?.bookingReducer?.doctorDetails,
  );

  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );

  const currentUser = useSelector(
    state => state?.homeReducer?.currentProfileName,
  );

  const uploadedFileList = useSelector(
    state => state?.uploadBookingReducer?.file_uploaded_list,
  );

  const currentProfileData = useSelector(
    state => state?.homeReducer?.currentProfileRawData,
  );

  const queryParams = new URLSearchParams(location.search);
  const pay_at_hospital = queryParams.get('pay_at_hospital');
  const slotInfo = queryParams.get('slotInfo');
  const item = JSON.parse(queryParams.get('item'));
  const date_info = JSON.parse(queryParams.get('date_info'));
//   const item = JSON.parse(queryParams.get('doctorSlot'));
//   const dropdownOptions = JSON.parse(queryParams.get('dropdownOptions'));
//   const unitAddress = queryParams.get('unitAddress');
//   const date = queryParams.get('date');
//   const page = queryParams.get('page');
  const [bookingErrorPopup, setBookingErrorPopup] = useState(false);
  // const [bookingErrorContent, setBookingErrorContent] = useState('');

  const [checkoutPressed, setCheckoutPressed] = useState(false);
  console.log('cccccccc', item);

  console.log('adrrr', slotAddress);
  console.log('detailsssssssss', slotDetails);
  console.log('timeeeee', slotStartTime);

  useEffect(() => {
    onApiCall();
  }, []);

  const onApiCall = async () => {
    const response = await reScheduleBooking(item.booking_id);
    if (isResponseIsValid(response)) {
      console.log('RESS', JSON.stringify(response.data));
      const rp = response.data.data;
      setDataList(rp);
    //   setReadMoreText(rp.description);
      console.log(
        'CONFIRM RESCHEDULE INITIAL RESPONSE ===========================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
        JSON.stringify(rp),
      );
    //   setLoadingData(false);
    }
  };

  function generateDateRange(startDate) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseCheckoutDialog1 = () => {
    // setCheckoutDialogOpen(false);
    setBookingErrorPopup(false);
  };

  const handleCloseCheckoutDialog = () => {
    setCheckoutDialogOpen(false);
    // setBookingErrorPopup(false);
    history.push('/');
  };
  console.log('solt iiiiiiiii', slotInfo);

  const rescheduleConfirmFunc = async () => {
    setLoader(true);
    const body = {
      patient_id: dataList.patient_id,
      doctor_id: dataList._id.$oid,
      unit_id: dataList.unit_details._id.$oid,
      // slot_id: slotInfo._id.$oid,
      _id: item.booking_id,
      // consult_date: `${slotInfo?.slot_date + 'T' + slotInfo?.slot_start_time}`,
      // consult_time: `${slotInfo?.slot_date + 'T' + slotInfo?.slot_start_time}`,
      consult_date: `${slotInfo?.slot_date + 'T' + slotStartTime + ':00'}`,
      consult_time: `${slotInfo?.slot_date + 'T' + slotStartTime + ':00'}`,
      last_consult_date: `${dataList.slot_date + 'T' + dataList.slot_time}`,
      ref_time: slotStartTime
    };
    console.log('RESCHEDULE SLOT BODY', body);

    try {
      const response = await updateReschedule(body);
      console.log('RESCHEDULE RESPONSE', JSON.stringify(response));
      if (isResponseIsValid(response)) {
        setLoader(false);
        setTimeout(() => {
        //   if(upcomingListPage == false){
        //   DeviceEventEmitter.emit('reScheduleData', {
        //     isShowPopup: true,
        //     is_reschedule: response.data.is_reschedule,
        //   });
        // }
        //   DeviceEventEmitter.emit('reScheduleDataList', {
        //     isShowPopup: true,
        //     is_reschedule: response.data.is_reschedule,
        //   });
        
        //   navigation.navigate('MainScreens', {screen: 'HomeScreen'});
        setCheckoutDialogOpen(true);
        history.push('/');
        }, 500);
      } else {
        setTimeout(() => {
          setLoader(false);
          console.log(response?.data?.message, 'RESCHEDULE ERROR');
          if (response?.data?.message) {
            setRescheduleErrorContent(response?.data?.message);
            setTimeout(() => {
                setBookingErrorPopup(true);
            }, 400);
          } else {
            // DeviceEventEmitter.emit('webHookData', {
            //   isShowPopup: true,
            //   item: 'reschedule',
            // });
            let pay_load = {
              isShowPopup: true,
              item: 'reschedule',
            }
            setWebHookCheckout('webhook_popup',JSON.stringify(pay_load))
            setTimeout(() => {
                // navigation.navigate('MainScreens', {screen: 'HomeScreen'});
                history.push('/');
            }, 500);
            // snackBar(Headers.apiError);
          }
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        setLoader(false);
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  return (
    <Container sx={{ p: 0, margin: 0 }}>
      <Header />
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, margin: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)', }}>
        <Avatar
          src={dataList.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{dataList.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(dataList.designation) ? dataList.designation.join(', ') : dataList.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{dataList.default_specialization}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {dataList.slot_range}</Typography>
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{dataList.languages ?
            (Array.isArray(dataList.languages) ? dataList.languages.join(', ') : dataList.languages) :
            'Tamil, English'}
          </Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {}</Typography> */}
        </Box>
      </Box>
      
        <Box display="flex" flexDirection='column' alignItems="left" mb={2} ml={8}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; {dataList.booking_type === 'Offline' ? 'Hospital Visit' : 'Video Consultation'}
          </Button>
          {/* <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>
            {`${date}, ${dataItem?.slot_details[0]?.slot_start_time}`}
          </Typography> */}

          {/* <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOnIcon color="primary" sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>
                {dataList?.unit_details?.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>
                {dataList?.unit_details?.address}
              </Typography>
            </Box>
          </Paper> */}

          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '16px', mt: 2 }}>
            Patient Details
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
              Name
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', mt: 2 }}>
              {dataList?.patient_name}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
              Notes to doctor
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins' , color: COLORS.textColor, fontSize: '12px', mt: 2 }}>
              {dataList?.notes}
            </Typography>
          </Box>

          <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' border='0px solid #00000029' borderRadius={1} width={{ xs: '20%', md: '20%' }} height='10%' boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029' mb={2} mt={2}>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
              {pay_at_hospital === 1 ? 'To pay' : 'Paid'}
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
              Rs. {dataList?.amount}
            </Typography>
          </Box>
            {item.booking_type === 'Online' && (
          <Box sx={{ mb: 2 }}>
            <ErrorOutlineIcon />
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
            You will receive a reminder 15 minutes before on the day of the call. Link for your call will be available here clicking on which will take you to the video consultation.
            </Typography>
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained"
              sx={{
                backgroundColor: '#962067',
                color: '#fff',
                p: 1,
                borderRadius: 8,
                textTransform: 'none',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                mt: 2,
                width: '20%',
                '&:hover': {
                  backgroundColor: '#962067',
                  borderColor: '#ffe6f2',
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`,
                },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`,
                },
              }}
              onClick={handleRescheduleOpen}
            >
              Join Now
            </Button>
            </Box> */}
          </Box>
      )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {/* {dataList?.is_avail_for_reschedule ? (
            <> */}
            <Button variant="contained"
              sx={{
                backgroundColor: '#962067',
                color: '#fff',
                p: 1,
                borderRadius: 8,
                textTransform: 'none',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                mt: 2,
                width: '20%',
                '&:hover': {
                  backgroundColor: '#962067',
                  borderColor: '#ffe6f2',
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`,
                },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`,
                },
              }}
              onClick={rescheduleConfirmFunc}
            >
              Reschedule
            </Button>
            {/* </> */}
          {/* ) : ( null )} 
          {dataList?.is_avail_for_cancellation ? (
            <>
            <Button variant="outlined" sx={{
              borderColor: '#962067',
              color: '#962067',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              fontFamily: 'Poppins',
              mt: 2,
              width: '20%',
              ml: 1,
              '&:hover': {
                backgroundColor: '#ffffff',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`,
              },
              ':active': {
                boxShadow: `0 0 0 2px transparent`,
              },
            }}
              onClick={handleCancelOpen}
            >
              Cancel appointment
            </Button>
            </>
          ) : ( null )} */}
          </Box>

          
        </Box>
        <Dialog open={isCheckoutDialogOpen} onClose={handleCloseCheckoutDialog}>

        <IconButton sx={{ fontSize: 48 }}>
          {/* <CancelIcon sx={{ fontSize: 'inherit', color: 'failure.main', mt: '20px' }} /> */}
          <CheckCircleIcon sx={{ fontSize: 'inherit', color: 'success.main', mt: '20px' }} />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
        Rescheduled
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
          Your appointment has been rescheduled successfully. Please find the details under upcoming events.
          </Typography>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseCheckoutDialog} variant="contained" sx={{
              backgroundColor: '#962067',
              color: '#fff',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              width: '30%',
              '&:hover': {
                backgroundColor: '#962067',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}>Okay</Button>
          </DialogActions>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
            <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
          </Typography>
        </DialogContent>

      </Dialog>
      <Dialog open={bookingErrorPopup} onClose={handleCloseCheckoutDialog1}>

        <IconButton sx={{ fontSize: 48 }}>
        <CancelIcon sx={{ fontSize: 'inherit', color: 'failure.main', mt: '20px' }} />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Poppins', mb: 1, color: COLORS.textColor }}>
          Error!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
            {bookingErrorContent}
          </Typography>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseCheckoutDialog1} variant="contained" sx={{
              backgroundColor: '#962067',
              color: '#fff',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
              width: '30%',
              '&:hover': {
                backgroundColor: '#962067',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}>Okay</Button>
          </DialogActions>
          {/* <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px', mb: '20px' }}>
            <span style={{ fontFamily: 'Poppins', color: COLORS.textColor }}>Note: </span>The appointment time is subject to last-minute change due to any unavoidable emergencies to be attended by the doctors.
          </Typography> */}
        </DialogContent>

      </Dialog>
     
    </Container>
  );
};

export default SelectPatient;