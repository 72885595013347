import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, TextField, InputAdornment, Box } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Header from './HeaderNew';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { COLORS } from '../constants/Theme';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector, useDispatch } from 'react-redux';
import { packageDetails, packageFee } from '../store/actions/mhcActions';
import { MHCListWithoutSearch } from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { getSecondaryProfileID } from '../utils/LocalStorage';

const CustomTextField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: '#e0e0e0',
    fontFamily: 'Poppins',
    fontSize: '12px',
    color: COLORS.placeholderColor,    // justifyContent: 'flex-end',
    height: '40px',
    marginBottom: '20px',
    marginTop: '20px',
    marginLeft: 'auto', // Adjust to align to the right
    // marginRight: 'right', // Adjust to center on smaller screens
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: COLORS.primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primaryColor,
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px',
    '&::placeholder': {
      color: '#000000',
    },
  },
  '@media (max-width: 600px)': {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
  },
});

const StyledCard = styled(Card)({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '10px',
  border: '1px solid #e0e0e0',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '10px',
  '@media (max-width: 600px)': {
    padding: '15px',
  },
});

const FeeTypography = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  color: COLORS.primaryColor,
  marginTop: '10px',
});

const BookButton = styled(Button)({
  backgroundColor: COLORS.primaryColor,
  color: '#fff',
  fontFamily: 'Poppins',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: COLORS.primaryColor,
  },
});

const SectionHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
});

const PackageCard = ({ packageDetails, selectedUnit, dropdownOptions }) => {
  const history = useHistory();

  // const handleBookNowClick = (selectedUnit, packageDetails, dropdownOptions) => {
  //   const queryParams = new URLSearchParams({
  //     packageId: packageDetails.PackageId,
  //     packageName: packageDetails.PackageName,
  //     description: packageDetails.Description,
  //     packagePrice: packageDetails.PackagePrice,
  //     ageFrom: packageDetails.AgeFrom,
  //     ageTo: packageDetails.AgeTo,
  //     gender: packageDetails.Gender,
  //     level: packageDetails.Level,
  //     preRequisite: JSON.stringify(packageDetails.PreRequisite),
  //     selectedUnit: selectedUnit,
  //     dropdownOptions: JSON.stringify(dropdownOptions)
  //   }).toString();

  //   history.push(`/pkgdetails?${queryParams}`);
  // };

  const handleBookNowClick = (selectedUnit, packageDetails, dropdownOptions) => {

  }

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h6" component="div" style={{fontFamily: 'Poppins', color: COLORS.textColor}}>
          {packageDetails.PackageName}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Poppins', color: COLORS.textColor}}>
          {packageDetails.Description}
        </Typography>
        <FeeTypography variant="body2" style={{fontFamily: 'Poppins'}}>
          Fee:         
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Poppins', color: COLORS.textColor}}>
        Rs. {packageDetails.PackagePrice}
        </Typography>
        </FeeTypography>
        <BookButton variant="contained" onClick={() => handleBookNowClick(selectedUnit, packageDetails)}>
          Book now
        </BookButton>
      </CardContent>
    </StyledCard>
  );
};

const HealthCheckupPage = () => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedUnit = queryParams.get('selectedUnit');
  // const dropdownOptions = queryParams.get('dropdownOptions');

  const [testData, setTestData] = useState([]);
  const [page, setPage] = useState(1);
  const [page_size, setPage_size] = useState(2);
  const [searchText, setSearchText] = useState('');
  const [loader, setLoader] = useState(false);
  const [profileID, setProfileID] = useState('');

  const [recommended_tests_count, setRecommended_tests_count] = useState(0);
  const [most_popular_count, setMost_popular_count] = useState(0);
  const dispatch = useDispatch();

  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );

  useEffect(() => {
    getSecondaryProfileID('Secondary_Profile_ID').then(res => {
      let json = JSON.parse(res);
      let profile_id = json
      MHCListApiCall(profile_id, true);
    });
  }, []);

  useEffect(() => {
    const reloadUpload = () => {
      setTimeout(() => {
        getSecondaryProfileID('Secondary_Profile_ID').then((res) => {
          const profile_id = JSON.parse(res);
          MHCListApiCall(profile_id, true);
        });
      }, 500);
    };

    window.addEventListener('reloadUpload', reloadUpload);

    return () => {
      window.removeEventListener('reloadUpload', reloadUpload);
    };
  }, []);

  const MHCListApiCall = async (profile_id) => {
    setLoader(true);
    // if (loader) {
    //   setLoader(true);
    // }
    try {
      console.log('MHC profile Id', profile_id);
      const response = await MHCListWithoutSearch({
        page: 1,
        page_size: 2,
        profile_id: profile_id,
        test_category: '',
      });
      console.log(JSON.stringify(response), 'MHC list response');
      if (isResponseIsValid(response)) {
        setTestData(response.data.data);
        setRecommended_tests_count(response.data.total_count);
        setMost_popular_count(response.data.total_count);
        setTimeout(() => {
          setLoader(false);
        }, 200);
      } else {
        setLoader(false);
        setTimeout(() => {
          if (response?.data) {
            snackBar(response?.data);
          } else {
            snackBar(Headers.apiError);
          }
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, 'err');
    }
  };

  // useEffect(() => {
  //   const initializeData = async () => {
  //     await fetchmhclist(selectedUnit);
  //   };
  //   initializeData();
  // }, [selectedUnit]);

  const onPressTestCard = item => {
    console.log(item, 'TEST CARD ITEM');
    dispatch(packageDetails(item));
    //dispatch(packageFee(Math.round(item.discount_price)));
    setTimeout(() => {
      // navigation.navigate('TestDetails', {test_id: item.id.$oid});
    }, 200);
  };

  const onPressBookNow = item => {
    // MHCBookNowEvent(item)
    dispatch(packageDetails(item));
    //dispatch(packageFee(Math.round(item.discount_price)));
    setTimeout(() => {
      // navigation.navigate('SelectDateLocation');
    }, 200);
  };

  const fetchmhclist = async (city) => {
    try {
      const response = await axios.get(`http://localhost:1801/api/mhclist/`, { params: { city } });
      setPackages(response.data);
    } catch (error) {
      console.error('Error fetching MHC list:', error);
    }
  };

  const convertedArray = packages.map(pkg => ({
    AgeFrom: pkg["Age From"],
    AgeTo: pkg["Age To"],
    Description: pkg.Description,
    Gender: pkg.Gender,
    Level: pkg.Level,
    PackageId: pkg["Package Id"],
    PackageName: pkg["Package Name"],
    PackagePrice: pkg["Package Price"],
    PreRequisite: pkg["Pre Requisite"]
  }));

  const handleUnitChange = (unit) => {
    // setSelectedUnit(unit);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPackages = convertedArray.filter(pkg =>
    pkg.PackageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    pkg.Description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <Container style={{padding: 0, margin: 0}}>
        <Header
          dropdownOptions={dropdownOptions}
          selectedUnit={selectedUnit}
          onUnitChange={handleUnitChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <CustomTextField
          variant="outlined"
          placeholder="Search for health checkups"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: COLORS.primaryColor }} />
              </InputAdornment>
            ),
          }}
        />
        </Box>
        <SectionHeader>
          <Typography variant="h5" sx={{ml: 1}}>Recommended tests</Typography>
        </SectionHeader>
    
        <Grid container spacing={2}>
          {filteredPackages.map((pkg, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <PackageCard packageDetails={pkg} selectedUnit={selectedUnit} dropdownOptions={dropdownOptions}/>
            </Grid>
          ))}
        </Grid>

        <SectionHeader>
          <Typography variant="h5" sx={{ml: 1}}>Most popular</Typography>
        </SectionHeader>
    
        <Grid container spacing={2}>
          {filteredPackages.map((pkg, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <PackageCard packageDetails={pkg} selectedUnit={selectedUnit} dropdownOptions={dropdownOptions}/>
            </Grid>
          ))}
        </Grid>
      </Container>
  );
};

export default HealthCheckupPage;
