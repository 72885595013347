import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Button, Grid, IconButton, Container, InputBase, Avatar, Modal } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FilterListIcon from '@mui/icons-material/FilterList';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { COLORS, FONTS } from '../constants/Theme';
import { useHistory, useLocation } from 'react-router-dom';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    upcomingEvents,
    updateBooking,
    specializationListWithUnitID,
    doctorsListInConsultation,
} from '../utils/apiCalls';
import SearchIcon from '@mui/icons-material/Search';
import Header from './HomeHeader';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const DoctorCard = ({ doctor, unitAddress, unitId, setDoctor, setOpenPopup, openPopup }) => {
    const history = useHistory();
    const [selectedUnit, setSelectedUnit] = useState('Chennai - Mylapore');
    // const [openPopup, setOpenPopup] = useState(false);
    // const [doctor, setDoctor] = useState([]);
    const handleSlots = (selectedUnit, item) => {
        // history.push({
        //     pathname: '/appointmentbook',
        //     state: { selectedUnit, item },
        //   });
        const queryParams = new URLSearchParams({
            selectedUnit: selectedUnit,
            item: JSON.stringify(item),
            //   dropdownOptions: dropdownOptions,
            unitAddress: unitAddress,
            unitid: unitId
            // item: item,
        }).toString();

        history.push(`/appointmentbook?${queryParams}`);
    };

    const onDoctorClick = (doctor) => {
        console.log('rrrrrrrr', doctor);
        setOpenPopup(true);
        setDoctor(doctor);
      }

      const onClose = () => {
        setOpenPopup(false);
    }

    return (
        <Paper elevation={2} sx={{ p: 2, mb: 2, borderRadius: '10px', display: 'flex', alignItems: 'center', }}
            onClick={() => onDoctorClick(doctor)}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={10} sm={10} md={11}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', }}>
                            <Avatar
                                alt={doctor.name}
                                src={doctor.photo}
                                sx={{ marginRight: 2, width: 85, height: 90 }}
                            />
                        </Box>

                        <Box sx={{ alignItems: 'center', mt: 2 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                            >
                                {doctor.first_name}
                            </Typography>
                            {/* <Typography
                            variant="caption"
                            sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                        >
                            {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
                        </Typography> */}
                            <Typography
                                variant="body2"
                                sx={{ color: COLORS.textColor, fontFamily: 'Poppins', fontSize: '11px' }}
                            >
                                {doctor.default_specialization}
                            </Typography>
                        </Box>
                        <Box sx={{ alignItems: 'center', ml: 8, mt: 2 }}>
                            <Typography
                                variant="caption"
                                sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                            >
                                Language: {doctor.languages ?
                                    (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                                    'Tamil, English'
                                }
                            </Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#666' }}>
                                {doctor.experience}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: doctor.isAvailable ? '#962067' : '#666', mb: 1 }}>
                                {doctor.availability}
                            </Typography>
                            {doctor.isAvailable && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {doctor.hospitalVisit && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                            <LocalHospitalIcon sx={{ color: '#962067', mr: 0.5 }} />
                                            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#666' }}>
                                                Hospital visit
                                            </Typography>
                                        </Box>
                                    )}
                                    {doctor.videoConsultation && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                            <VideoCallIcon sx={{ color: '#962067', mr: 0.5 }} />
                                            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#666' }}>
                                                Video consultation
                                            </Typography>
                                        </Box>
                                    )}
                                    {doctor.time && (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <AccessTimeIcon sx={{ color: '#962067', mr: 0.5 }} />
                                            <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: '#666' }}>
                                                {doctor.time}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Button
                onClick={() => {
                    if (doctor.request_only === 0) {
                        handleSlots(selectedUnit, doctor);
                    } else {
                        // handleRequest(selectedUnit, doctor);
                    }
                }}
                sx={{
                    backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
                    color: 'white',
                    fontFamily: 'Poppins',
                    minWidth: '100px',
                    fontSize: '12px',
                    padding: '1px 14px',
                    textTransform: 'none',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '15px',
                    '&:hover': {
                        backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
                    },
                    ':focus': {
                        outline: 'none',
                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                    },
                }}
            >
                {doctor.request_only === 0 ? 'Book Now' : 'Request'}
            </Button>
            <Modal open={openPopup} onClose={onClose} aria-labelledby="doctor-popup" aria-describedby="doctor-details">

                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', sm: '70%', md: '70%' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none',
                    maxHeight: '80%',
                    overflowY: 'auto'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <Typography variant="h6" id="doctor-popup" sx={{ color: COLORS.primaryColor, fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '16px', }}>
                            About the doctor
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon color={COLORS.primaryColor} />
                        </IconButton>
                    </Box>
                    {doctor ? (
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    {/* <Avatar src={doctor[0].photo} alt={doctor.first_name} sx={{ width: 60, height: 60, mr: 2 }} />
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{doctor[0].first_name}</Typography> */}
                                    {/* <Typography variant="body2" color="textSecondary">{doctor && doctor.designation.join(', ')}</Typography> */}
                                    {/* </Box> */}

                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)',}}> */}
                                    <Avatar
                                        src={doctor.photo ? doctor.photo : ''}
                                        alt="Doctor Image"
                                        sx={{ width: 50, height: 55, marginRight: 2, ml: 1, mt: 1, }}
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '15px' }}>{doctor.first_name}</Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}</Typography>
                                    </Box>
                                    <Box sx={{ ml: 8 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{doctor.default_specialization}</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                    <Box sx={{ ml: 12 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{doctor.languages ?
                                            (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                                            'Tamil, English'
                                        }</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                    {/* <Typography variant="body2">{doctor.default_specialization}</Typography> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                        {/* <Typography variant="body2">{doctor.available_time.start_time && doctor.available_time.start_time}-{doctor.available_time.end_time && doctor.available_time.end_time}</Typography> */}
                                    </Box>
                                </Box>

                                <Typography variant="body2" sx={{ mt: 2, color: COLORS.textColor, fontFamily: 'Poppins', textAlign: 'justify' }}>
                                    {doctor.description}
                                </Typography>
                            </Box>
                        </Box>
                    ) : ('')}
                </Box>
            </Modal>
        </Paper>
    );
};

const AvailableDoctors = () => {
    const location = useLocation();
    const history = useHistory();
    const [doctorsDetails, setDoctorsDetails] = useState([]);
    const [page_size, setPage_size] = useState(80);
    const [page, setPage] = useState(1);
    const [next_page, setNext_page] = useState(null);
    const [doctorListCount, setDoctorListCount] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    // const displayedDoctors = showAll1 ? doctorsDetails : doctorsDetails.slice(0, 4);
    const [loader, setLoader] = useState(false);
    const [initialLoader, setInitialLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [doctor, setDoctor] = useState('');
    const queryParams = new URLSearchParams(location.search);
    // const selectedUnit = queryParams.get('selectedUnit');
    const unitAddres = queryParams.get('unitAddress');
    const unitId = queryParams.get('unitid');
    const city = queryParams.get('city');
    const [unitAddress, setUnitAddress] = useState(unitAddres);
    // const doctors = JSON.parse(queryParams.get('doctors'));
    // const displayedDoctors = doctors.slice(0, 4);
    const specialtyId = queryParams.get('spId');
    const spec_name = queryParams.get('specName');

    useEffect(() => {
        const initializeData = async () => {

            specialityDoctorList();
            // }
        };

        initializeData();
    }, []);

    const specialityDoctorList = async () => {
        try {

            const data = [(
                unitId,
                city,
                page,
                page_size,
                search,
                specialtyId,
                spec_name
            )];
            console.log('aaaaaaa', data);
            const doctorsResponse = await doctorsListInConsultation(
                unitId,
                city,
                1,
                5,
                search,
                specialtyId,
                spec_name,
            );

            if (isResponseIsValid(doctorsResponse)) {
                if (page == 1) {
                    setDoctorListCount(doctorsResponse.data.total_count);
                    setDoctorsDetails(doctorsResponse.data.data);

                }
                else {
                    setDoctorsDetails(prevDoctorsDetails => [
                        ...prevDoctorsDetails,
                        ...doctorsResponse.data.data,
                    ]);
                    console.log('dcrrrrr', doctorsDetails);
                }
            } else {
                if (doctorsResponse?.data) {
                    snackBar(doctorsResponse?.data);
                } else {
                    snackBar(Headers.apiError);
                }
            }
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
            }, 400);
        } catch (error) {
            setTimeout(() => {
                setLoader(false);
                setInitialLoader(false);
                snackBar(Headers.apiError);
            }, 500);

        }
    };

    const onClose = () => {
        setOpenPopup(false);
    }

    const onDoctorClick = (doctor) => {
        console.log('rrrrrrrr', doctor);
        setOpenPopup(true);
        setDoctor(doctor);
      }

    const handleSlots = (selectedUnit, item) => {
        // history.push({
        //     pathname: '/appointmentbook',
        //     state: { selectedUnit, item },
        //   });
        const queryParams = new URLSearchParams({
            //   selectedUnit: selectedUnit,
            //   item: JSON.stringify(item),
            //   dropdownOptions: dropdownOptions,
            unitAddress: unitAddress,
            unitid: unitId
            // item: item,
        }).toString();

        history.push(`/appointmentbook?${queryParams}`);
    };

    return (
        <Container sx={{ p: 0, margin: 0 }}>
            <Header />
            <Box sx={{ padding: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px', p: 0, margin: 0, width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderRadius: '50px', boxShadow: 1, backgroundColor: '#f5f5f5', alignItems: 'center', mb: 2, height: '30px' }}>
                        <InputBase
                            placeholder="Search for doctors and specialties"
                            sx={{ ml: 2, flex: 1, fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}
                        />
                        <IconButton sx={{
                            p: '10px', ':focus': {
                                outline: 'none',
                                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                            },
                            ':hover': {
                                backgroundColor: 'transparent',
                            },
                            ':active': {
                                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                            },
                        }}>
                            <SearchIcon sx={{ color: COLORS.primaryColor }} />
                        </IconButton>
                    </Box>
                    <IconButton sx={{
                        ml: 2, mb: 2, ':focus': {
                            outline: 'none',
                            boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                        },
                        ':hover': {
                            backgroundColor: 'transparent',
                        },
                        ':active': {
                            boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                        },
                    }}>
                        <FilterListIcon sx={{ color: COLORS.primaryColor }} />
                    </IconButton>
                </Box>
                <Box sx={{ p: 2, maxWidth: '900px', mx: 'auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <IconButton onClick={() => window.history.back()} sx={{ color: '#962067', size: '14px' }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 'normal', color: '#962067', ml: 1, fontSize: '16px' }}>
                            Available doctors
                        </Typography>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                        <Button
                            variant="outlined"
                            startIcon={<LocationOnIcon />}
                            sx={{
                                textTransform: 'none',
                                borderColor: '#962067',
                                color: '#962067',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                            }}
                        >
                            Chennai - Alwarpet
                        </Button>
                    </Box> */}
                    {doctorsDetails.map((doctor, index) => (
                        <DoctorCard key={index} doctor={doctor} unitAddres={unitAddres} unitId={unitId} handleSlots={handleSlots} onDoctorClick={onDoctorClick} onClose={onClose} setDoctor={setDoctor} setOpenPopup={setOpenPopup} openPopup={openPopup}/>
                    ))}
                </Box>
            </Box>
        </Container>
    );
};

export default AvailableDoctors;
