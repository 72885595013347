import BackgroundImage from '../assets/images/bg1.jpg'; // Adjust the path as necessary
import { COLORS, FONTS, Fonts } from '../constants/Theme'; // Import your theme constants

export const styles = {
  signupContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: '#FFFFFFCC',
  },
  backgroundBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '130%',
    backgroundPosition: '150%',
    filter: 'blur(0px)',
    zIndex: -1,
  },
  loader: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    color: '#fff',
    fontSize: '24px',
  },
  heading: {
    // ...FONTS.h1,
    color: COLORS.textColor,
    marginBottom: '20px',
    textAlign: 'left',
    justifyContent: 'left',
    fontSize: '18px',
    fontFamily: 'Poppins'
  },
  subheading: {
    // ...FONTS.body3,
    color: COLORS.textColor,
    marginBottom: '20px',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Poppins'
  },
  form: {
    // width: '100%',
    // maxWidth: '500px',
    // backgroundColor: 'rgba(255, 255, 255, 0.8)',
    // borderRadius: '8px',
    // padding: '20px',
    // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    // backdropFilter: 'blur(10px)',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    maxWidth: '500px',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    // margin: '20px',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '0px',
    fontSize: '10px',
    color: COLORS.textColor,
    fontFamily: 'Poppins'
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '12px',
    color: COLORS.placeholderColor,
    border: `0px solid ${COLORS.borderColor}`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontFamily: 'Poppins'
  },
  select: {
    width: '100%',
    padding: '10px',
    fontSize: '12px',
    color: COLORS.placeholderColor,
    border: `0px solid ${COLORS.borderColor}`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0.1, 0.1)',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  searchResults: {
    listStyle: 'none',
    padding: 0,
    margin: '10px 0 0 0',
    maxHeight: '150px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    border: `1px solid ${COLORS.borderColor}`,
    borderRadius: '4px',
  },
  searchResultItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.borderColor}`,
  },
  button: (enabled) => ({
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: enabled ? COLORS.primaryColor : COLORS.disabledColor,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.8s',
    opacity: enabled ? 1 : 1,
  }),
  errorInput: {
    border: `1px solid ${COLORS.errorColor}`,
  },
  errorText: {
    color: COLORS.errorColor,
    fontSize: '12px',
    marginTop: '4px',
  },
  '@media (max-width: 768px)': {
    form: {
      maxWidth: '100%',
      padding: '15px',
    },
    heading: {
      // fontSize: '24px',
      // marginBottom: '15px',
      color: COLORS.textColor,
      marginBottom: '20px',
      textAlign: 'left',
      justifyContent: 'left',
      fontSize: '18px',
      fontFamily: 'Poppins'
    },
    subheading: {
      fontSize: '16px',
      marginBottom: '20px',
    },
  },
  '@media (max-width: 480px)': {
    form: {
      padding: '10px',
    },
    heading: {
      // fontSize: '20px',
      // marginBottom: '10px',
      color: COLORS.textColor,
      marginBottom: '20px',
      textAlign: 'left',
      fontSize: '18px',
      fontFamily: 'Poppins',
      justifyContent: 'left'
    },
    subheading: {
      fontSize: '14px',
      marginBottom: '20px',
    },
  },
};
