import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, TextField, InputAdornment, Select, MenuItem, OutlinedInput, Box, InputBase, IconButton } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Header from './HomeHeader';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { COLORS } from '../constants/Theme';
// import SearchIcon from '@mui/icons-material/Search';
import {getSecondaryProfileID} from '../utils/LocalStorage';
import { RiMicroscopeLine } from "react-icons/ri";
import {
  addFeedback,
  getFeedbackList,
  familyMembersList,
  getProfileApi,
  units,
  nearbyUnits,
  nearbyUnitsCoordinates,
  nearbyUnitsRegion,
  nearbyUnitsWithoutRegion,
  MHCListApiCall,
  MHCListWithoutSearch
} from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { profileImageSaved, unitNameData } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomTextField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: '#e0e0e0',
    fontFamily: 'Poppins',
    fontSize: '12px',
    color: COLORS.placeholderColor,    // justifyContent: 'flex-end',
    height: '40px',
    marginBottom: '20px',
    marginTop: '20px',
    marginLeft: 'auto', // Adjust to align to the right
    // marginRight: 'right', // Adjust to center on smaller screens
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: COLORS.primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primaryColor,
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px',
    '&::placeholder': {
      color: '#000000',
    },
  },
  '@media (max-width: 600px)': {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
  },
});

const StyledCard = styled(Card)({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '5px',
  border: '1px solid #e0e0e0',
  borderRadius: '5px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '10px',
  '@media (max-width: 600px)': {
    padding: '15px',
  },
});

const FeeTypography = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  color: COLORS.primaryColor,
  marginTop: '10px',
});

const BookButton = styled(Button)({
  backgroundColor: COLORS.primaryColor,
  color: '#fff',
  fontFamily: 'Poppins',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: COLORS.primaryColor,
  },
});

const SectionHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
});

const PackageCard = ({ packageDetails, selectedUnit, dropdownOptions }) => {
  const history = useHistory();

  const handleBookNowClick = (selectedUnit, packageDetails, dropdownOptions) => {
    const queryParams = new URLSearchParams({
      packageId: packageDetails.PackageId,
      packageName: packageDetails.PackageName,
      description: packageDetails.Description,
      packagePrice: packageDetails.PackagePrice,
      ageFrom: packageDetails.AgeFrom,
      ageTo: packageDetails.AgeTo,
      gender: packageDetails.Gender,
      level: packageDetails.Level,
      preRequisite: JSON.stringify(packageDetails.PreRequisite),
      selectedUnit: selectedUnit,
      dropdownOptions: JSON.stringify(dropdownOptions)
    }).toString();
    history.push(`/pkgdetails?${queryParams}`);
    // history.push(`/mhcscreen`);
  };

  const count = '10';
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h6" component="div" style={{fontFamily: 'Poppins', color: COLORS.textColor}}>
          {packageDetails.PackageName}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Poppins', color: COLORS.textColor, marginBottom: '10px'}}>
          {packageDetails.Description}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Poppins', color: COLORS.textColor, mt: 1, fontSize: '12px'}}>
        <RiMicroscopeLine />
        {count} tests included
        </Typography>
        <FeeTypography variant="body2" style={{fontFamily: 'Poppins'}}>
          Fee:         
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Poppins', color: COLORS.textColor}}>
        Rs. {packageDetails.PackagePrice}
        </Typography>
        </FeeTypography>
        {/* <BookButton variant="contained" onClick={() => handleBookNowClick(selectedUnit, packageDetails)}>
          Book now
        </BookButton> */}
        <Button
                onClick={() => handleBookNowClick(selectedUnit, packageDetails)}
                sx={{
                  backgroundColor: COLORS.primaryColor,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mr: 2,
                  ml: 40,
                  fontFamily: 'Poppins',
                  minWidth: '100px',
                  fontSize: '12px',
                  padding: '1px 14px',
                  textTransform: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: COLORS.primaryColor,
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                  },
                }}
              >
                Book Now
              </Button>
      </CardContent>
    </StyledCard>
  );
};

const HealthCheckupPage = () => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedUnit, setSelectedUnit] = useState('Chennai - Mylapore');
  // const [selectedUnit1, setSelectedUnit1] = useState('Chennai - Mylapore');
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  // const dropdownOptions = queryParams.get('dropdownOptions');
  const [recommended_tests_count, setRecommended_tests_count] = useState(0);
  const [most_popular_count, setMost_popular_count] = useState(0);
  const [loader, setLoader] = useState(false);
  const [unitAddress, setUnitAddress] = useState('');

  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );

  console.log('nameeeee', name);

  useEffect(() => {
    fetchLocations();
    onCallProfileApi();
    const initializeData = async () => {
      if(name){        
          getSecondaryProfileID('Secondary_Profile_ID').then(res => {
            let json = JSON.parse(res);
            let profile_id = json
            MHCListApiCall(profile_id, true);
          });       
      }
      else{
      await fetchmhclist(selectedUnit);
      }
    };
    initializeData();
    
  }, [selectedUnit]);

  const fetchLocations = async () => {

    try {
      // const tokenUrl = 'http://192.168.28.86/KAPP_INFOSHARE/api/Values/GetUnit';
      const tokenUrl = 'http://localhost:1801/api/unit';
      const locationResponse = await axios.get(tokenUrl, {
        headers: {
          // 'Authorization': `Bearer ${token}`
        }
      });

      setDropdownOptions(locationResponse.data.Result);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const MHCListApiCall = async (profile_id) => {
    setLoader(true);
    // if (loader) {
    //   setLoader(true);
    // }
    try {
      console.log('MHC profile Id', profile_id);
      const response = await MHCListWithoutSearch({
        page: 1,
        page_size: 2,
        profile_id: profile_id,
        test_category: '',
      });
      console.log(JSON.stringify(response), 'MHC list response');
      if (isResponseIsValid(response)) {
        setPackages(response.data.data);
        setRecommended_tests_count(response.data.total_count);
        setMost_popular_count(response.data.total_count);
        setTimeout(() => {
          setLoader(false);
        }, 200);
      } else {
        setLoader(false);
        setTimeout(() => {
          if (response?.data) {
            snackBar(response?.data);
          } else {
            snackBar(Headers.apiError);
          }
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      console.log(err, 'err');
    }
  };

  const onCallProfileApi = async () => {
    const response = await getProfileApi();
    console.log(response);
    if (isResponseIsValid(response)) {
        dispatch(profileImageSaved(response.data.photo ? response.data.photo : ''));
        setName(response.data.name);
    }
};

  const fetchmhclist = async (city) => {
    try {
      const response = await axios.get(`http://localhost:1801/api/mhclist/`, { params: { city } });
      setPackages(response.data);
    } catch (error) {
      console.error('Error fetching MHC list:', error);
    }
  };

  const convertedArray = packages.map(pkg => ({
    AgeFrom: pkg["Age From"],
    AgeTo: pkg["Age To"],
    Description: pkg.Description,
    Gender: pkg.Gender,
    Level: pkg.Level,
    PackageId: pkg["Package Id"],
    PackageName: pkg["Package Name"],
    PackagePrice: pkg["Package Price"],
    PreRequisite: pkg["Pre Requisite"]
  }));

  const handleUnitChange = (unit) => {
    // setSelectedUnit(unit);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPackages = convertedArray.filter(pkg =>
    pkg.PackageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    pkg.Description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (event) => {
    // console.log('inside');
    const selectedUnitName = event.target.value;
    setSelectedUnit(selectedUnitName);
    const selectedOption = dropdownOptions.find(option => option.UnitName === selectedUnitName);
    console.log('adrsssssssss', selectedOption);
    if (selectedOption) {
      setUnitAddress(selectedOption.Address);
      // setUnitid(selectedOption.UnitId);
    }
    // sendSelectedUnitToAPI(event.target.value);
  };

  return (
      <Container style={{padding: 0, margin: 0}}>
        <Header
          dropdownOptions={dropdownOptions}
          selectedUnit={selectedUnit}
          onUnitChange={handleUnitChange}
        />
          {/* <Box sx={{ padding: 0 }}> */}
        {/* Specialties Section */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderRadius: '50px', boxShadow: 1, backgroundColor: '#f5f5f5', alignItems: 'center', mb: 2, height: '30px' }}>
            <InputBase
              placeholder="Search for doctors and specialties"
              sx={{ ml: 2, flex: 1, fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}
            />
            <IconButton sx={{
              p: '10px', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}>
              <SearchIcon sx={{ color: COLORS.primaryColor }} />
            </IconButton>
          </Box>
          <IconButton sx={{
            ml: 2, mb: 2, ':focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':active': {
              boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
            },
          }}>
            <FilterListIcon sx={{ color: COLORS.primaryColor }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          {/* <LocationOnIcon sx={{}} /> */}
          <Select
            value={selectedUnit}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select location' }}
            IconComponent={ArrowDropDownIcon}
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnIcon sx={{ color: COLORS.primaryColor, fontSize: '16px' }} />
                  </InputAdornment>
                }
                sx={{
                  height: '25px',
                  fontFamily: 'Poppins',
                  color: COLORS.textColor,
                  fontSize: '13px',
                  alignItems: 'center',
                  borderRadius: '9px',
                  ':hover': {
                    backgroundColor: 'transparent',
                  },
                  ':active': {
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Corrected syntax for boxShadow
                  },
                }}
              />
            }
          >
            <MenuItem disabled value="" sx={{ fontFamily: 'Poppins', fontSize: '13px', }}>
              {selectedUnit}
            </MenuItem>
            {dropdownOptions.map(option => (
              <MenuItem key={option.id} value={option.UnitName} sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '13px', }}>
                {option.UnitName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <CustomTextField
          variant="outlined"
          placeholder="Search for health checkups"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: COLORS.primaryColor }} />
              </InputAdornment>
            ),
          }}
        />
        </Box> */}
        <SectionHeader>
          <Typography variant="h5" sx={{ml: 1}}>Most popular</Typography>
        </SectionHeader>
    
        <Grid container spacing={2}>
          {filteredPackages.map((pkg, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <PackageCard packageDetails={pkg} selectedUnit={selectedUnit} dropdownOptions={dropdownOptions}/>
            </Grid>
          ))}
        </Grid>
      </Container>
  );
};

export default HealthCheckupPage;
