import React, { useEffect, useState } from 'react';
import { Container, CircularProgress, Typography, Box, Button } from '@mui/material';
import { smallHtmlText } from '../utils/fontfamily';
import { getTermsApi } from '../utils/apiCalls';
import { isResponseIsValid } from '../utils/helpers';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';

const TermsAndConditionsPage = () => {
  const [termsData, setTermsData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    setLoading(true);
    const response = await getTermsApi();
    if (isResponseIsValid(response)) {
      setTermsData(response.data.data);
    }
    setLoading(false);
  };

  return (
    <Box style={{ margin: '0px', padding: '0px' }}>
        <Header />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px' }}>
          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderRadius: '50px', boxShadow: 1, backgroundColor: '#f5f5f5', alignItems: 'center', mb: 2, height: '30px' }}>
            <InputBase
              placeholder="Search for health records"
              sx={{ ml: 2, flex: 1, fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}
            />
            <IconButton sx={{
              p: '10px', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}>
              <SearchIcon sx={{ color: COLORS.primaryColor }} />
            </IconButton>
          </Box> */}
          </Box>
        <Box display="flex" alignItems="center" mb={0}>
        {/* <ArrowBackIosIcon style={{ marginRight: '0.5rem', cursor: 'pointer' }} />
        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>My Account</Typography> */}
      <Button
        onClick={() => window.history.back()}
        sx={{ marginTop: '20px', marginBottom: 2, marginLeft: '30px', fontSize: '16px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none' }}
      >
        &lt; Terms and Conditions
      </Button>
      </Box>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', marginLeft: '30px' }}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{ marginTop: '0px', backgroundColor: '#ffffff', borderRadius: '8px', maxHeight: '80vh', marginLeft: '30px', fontFamily: 'Poppins'}}
          dangerouslySetInnerHTML={{ __html: smallHtmlText('Poppins-Medium', 'ttf', termsData) }}
        />
      )}
    </Box>
  );
};

export default TermsAndConditionsPage;
