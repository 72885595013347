import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, Container, Menu, MenuItem } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isResponseIsValid } from '../utils/helpers';
import { profileImageSaved } from '../store/actions/authActions';
import { getProfileApi } from '../utils/apiCalls';
import { COLORS } from '../constants/Theme';
import Images from '../constants/Images';
import HomeIcon from '@mui/icons-material/Home';
import { FaStethoscope } from "react-icons/fa";
import { RiMicroscopeLine } from "react-icons/ri";
import { GrNotes } from "react-icons/gr";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Header = ({ dropdownOptions, selectedUnit, unitAddress, unitId, city }) => {
  const [name, setName] = useState('');
  const [photo, setPhoto] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
console.log(unitId, 'ssssssssss');
  useEffect(() => {
    onCallProfileApi();
  }, []);

  const onCallProfileApi = async () => {
    const response = await getProfileApi();
    if (isResponseIsValid(response)) {
      dispatch(profileImageSaved(response.data.photo));
      setName(response.data.name);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  const handleBookinglist = () => {
    const queryParams = new URLSearchParams({
        dropdownOptions: dropdownOptions,
        selectedUnit: selectedUnit,
        unitAddress: unitAddress,
        unitid: unitId,
        city: city
    }).toString();

    return `/specialitydoctor?${queryParams}`;
};
  const isConsultActive =
    location.pathname.startsWith('/specialitydoctor') ||
    location.pathname.startsWith('/appointmentbooking') ||
    location.pathname.startsWith('/selectpatient');

    const isHomeActive = location.pathname.startsWith('/');

    const isMhcActive =
    location.pathname.startsWith('/mhclist') ||
    location.pathname.startsWith('/mhc') ||
    location.pathname.startsWith('/mhcbooking');

    const isRecordActive =
    location.pathname.startsWith('/records') ||
    location.pathname.startsWith('/appointmentbooking') ||
    location.pathname.startsWith('/selectpatient');


  return (
    <Box sx={{ display: 'flex', padding: { xs: 0, md: 0 }, backgroundColor: '#fff', flexDirection: { xs: 'column', md: 'row' } }}>
      {/* Left Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', ml: { xs: 1, md: 4 }, mt: 1, mb: 1, flexGrow: 1 }}>
        <img src={Images.kauveryLogo} alt="Kauvery Hospital Logo" style={{ height: '60px', width: 'auto', marginRight: '16px' }} />
      </Box>

      {/* Middle Section (Nav Links) */}
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, flexGrow: 7, flexWrap: 'wrap', mt: 2 }}>
      <NavLink
        to="/"
        style={{
          color: isHomeActive ? COLORS.primaryColor : COLORS.placeholderColor, // pink when active
          textDecoration: 'none',
        }}
      >
        <Button startIcon={<HomeIcon />} sx={{ color: COLORS.placeholderColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: { xs: '14px', md: '16px' }, fontWeight: 500, mr: { xs: 2, md: 1 }, '&:hover': { backgroundColor: '#eee' },
                            '&:active': { backgroundColor: '#eee', outline: '#ffffff', color: COLORS.primaryColor },
                            '&:focus': { backgroundColor: '#eee', outline: '#ffffff' },}}>
          Home  |
        </Button>
        </NavLink>
        <NavLink
        to={handleBookinglist()}
        style={{
          color: isConsultActive ? COLORS.primaryColor : COLORS.placeholderColor, // pink when active
          textDecoration: 'none',
        }}
      >
        <Button startIcon={<FaStethoscope />} sx={{ color: COLORS.placeholderColor, textTransform: 'none', fontFamily: 'Poppins', fontSize: { xs: '14px', md: '16px' }, fontWeight: 500, mr: { xs: 2, md: 1 }, '&:hover': { backgroundColor: '#eee' },
                            '&:active': { backgroundColor: '#eee', outline: '#ffffff' },
                            '&:focus': { backgroundColor: '#eee', outline: '#ffffff' }, }}>
          Consult  |
        </Button>
        </NavLink>
        <NavLink
        to="/mhclist"
        style={{
          color: isMhcActive ? COLORS.primaryColor : COLORS.placeholderColor, // pink when active
          textDecoration: 'none',
        }}
      >
        <Button startIcon={<RiMicroscopeLine />} sx={{ color: COLORS.placeholderColor, textTransform: 'none', fontFamily: 'Poppins', fontSize: { xs: '14px', md: '16px' }, fontWeight: 500, mr: { xs: 2, md: 1 }, '&:hover': { backgroundColor: '#eee' },
                            '&:active': { backgroundColor: '#eee', outline: '#ffffff' },
                            '&:focus': { backgroundColor: '#eee', outline: '#ffffff' }, }}>
          MHC  |
        </Button>
        </NavLink>
        <NavLink
        to="/records"
        style={{
          color: isRecordActive ? COLORS.primaryColor : COLORS.placeholderColor, // pink when active
          textDecoration: 'none',
        }}
      >
        <Button startIcon={<GrNotes />} sx={{ color: COLORS.placeholderColor, textTransform: 'none', fontFamily: 'Poppins', fontSize: { xs: '14px', md: '16px' }, fontWeight: 500, mr: { xs: 2, md: 1 }, '&:hover': { backgroundColor: '#eee' },
                            '&:active': { backgroundColor: '#eee', outline: '#ffffff' },
                            '&:focus': { backgroundColor: '#eee', outline: '#ffffff' }, }}>
          Records |
        </Button>
        </NavLink>
      </Box>

      {/* Right Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-end' }, flexGrow: 1, mt: { xs: 1, md: 0 } }}>
        {photo ? (
          <>
            <img src={photo} alt="Profile" style={{ height: '40px', width: '40px', borderRadius: '50%', marginRight: '10px' }} />
            <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Poppins', color: COLORS.primaryBackgroundColor }}>{name}</Typography>
          </>
        ) : (
          <>
            <AccountCircleIcon sx={{ fontSize: 40, marginRight: '10px', color: COLORS.placeholderColor }} />
            <Button onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', textTransform: 'none', color: COLORS.placeholderColor, 
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
             }}>
              <Typography variant="body1" sx={{ fontWeight: 500, fontFamily: 'Poppins' }}>{name}</Typography>
              <ExpandMoreIcon style={{ color: COLORS.primaryColor }} />
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Sign out</MenuItem>
            </Menu>
          </>
        )}
        <IconButton sx={{ marginLeft: 2 }}>
          <NotificationsIcon sx={{ color: COLORS.primaryColor }} />
          <span style={{
            position: 'absolute',
            top: 5,
            right: 5,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            color: '#fff',
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {/* Notification count */}
          </span>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
