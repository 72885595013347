// urls.js

const URL = {
  development: 'https://kauveryappservice.underdev.in/',
  testing: 'http://kauveryapptestservice.underdev.in/',
  UAT: '',
  production: 'https://prime.kauverykonnect.com/',
  staging: 'https://staging-kauveryappservice.underdev.in/',
};

const BASE_URL = URL.development;

export {  
  BASE_URL,
};
