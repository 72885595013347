const auth_content = {
    MobileNumber: "Mobile Number",
    countryCode: "+91",
  
    // Placeholder Text
    PhoneNumber: "00000 00000",
  
    // Button Text
    Verify: "Verify",
    VerifyOTP: "Verify OTP",
  };
  
  export { auth_content };
  