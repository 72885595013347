import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Paper, IconButton, Container, Avatar } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Header from './HomeHeader';
import { useLocation, useHistory } from 'react-router-dom';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import { formatDateTime } from '../utils/helpers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EventEmitter } from 'events';
import {
  cancelBookingAPICall,
  getReasonAPICall,
  getTermsApi,
  reScheduleBooking,
} from '../utils/apiCalls';
import { COLORS } from '../constants/Theme';

const eventEmitter = new EventEmitter();

const AppointmentPage = () => {
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const item = JSON.parse(queryParams.get('item'));
  const dataItem = JSON.parse(queryParams.get('dataItem'));
  const unitAddress = queryParams.get('pay_at_hospital');
  const doctor_id = queryParams.get('doctor_id');
  // const page = queryParams.get('page');

  const handleRescheduleOpen = () => setRescheduleOpen(true);
  const handleRescheduleClose = () => setRescheduleOpen(false);

  const handleCancelOpen = () => setCancelOpen(true);
  const handleCancelClose = () => setCancelOpen(false);
  const [dataList, setDataList] = useState({});
  const [readMoreText, setReadMoreText] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  // const tabBarHeight = useBottomTabBarHeight();
  const [textShown, setTextShown] = useState(false); //To show ur remaining Text
  const [lengthMore, setLengthMore] = useState(false); //to show the "Read more & Less Line"
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [notes, setNotes] = useState('');
  const [notesErrorState, SetNotesErrorState] = useState(false);
  const [notesErrorText, SetNotesErrorText] = useState('');

  const [cancelPopupShown, setCancelPopupShown] = useState(false);
  const [rescheduleConfirmPopup, setRescheduleConfirmPopup] = useState(false);
  const [feedbackPopupShown, setFeedbackPopupShown] = useState(false);
  const [feedBack, setFeedBack] = useState('');
  const [feedBackErrorState, setFeedBackErrorState] = useState(false);
  const [feedBackErrorText, setFeedBackErrorText] = useState('');
  const [reason, setReason] = useState('');
  const [reasonList, setReasonList] = useState([]);
  const [BookingCancelpopup, setBookingCancalpopup] = useState(false);

  const [cancelErrorPopup, setCancelErrorPopup] = useState(false);
  const [cancelErrorContent, setCancelErrorContent] = useState('');
  const [pay_at_hospital, setPay_at_hospital] = useState(0);
  //For terms and condition
  const [termsModalOpen, setTermsModalopen] = useState(false)
  const [termsData, setTermsData] = useState([])
  const selectReasonSheet = useRef();
  const profile_information = useSelector(
    state => state?.homeReducer?.profile_info,
  );
  const upcomingListPage = useSelector(
    state => state?.homeReducer?.upcomingListPageShown,
  );
  const date = new Date(dataList.slot_date).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });

  // const time={dataList.slot_details[0].slot_start_time}
  console.log('itemmmm', item);
  useEffect(() => {
    onApiCall();
    getReason();
    onCallTermsApi();
    eventEmitter.addListener('rescheduleErrorOk', event => {
      console.log('Event', event);
      if (event.okPressed) {
        onInitialApiCall();
      }
    });
    return () => {
      eventEmitter.removeAllListeners('rescheduleErrorOk');
    };
  }, []);
  const onApiCall = async () => {
    console.log(dataItem.booking_id, 'BOOKING ID')
    const response = await reScheduleBooking(dataItem.booking_id);
    console.log('RESS23121DATA', JSON.stringify(response));
    if (isResponseIsValid(response)) {
      console.log('RESS23121', JSON.stringify(response.data));

      const rp = response.data.data;
      setDataList(rp);
      setPay_at_hospital(rp.pay_at_hospital);
      setReadMoreText(rp.description);
      console.log('RESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', JSON.stringify(rp));


      console.log(rp?.unit_details?.name, 'UNIT NAME IN RESCHEDULE')

      setLoadingData(false);
    } else {
      setLoadingData(false);
      // setBookingCancalpopup(true);
    }
  };




  const onInitialApiCall = async () => {
    setLoader(true);
    const response = await reScheduleBooking(dataItem.booking_id);
    if (isResponseIsValid(response)) {
      console.log('RESS231', JSON.stringify(response.data));

      const rp = response.data.data;
      setDataList(rp);
      setPay_at_hospital(rp.pay_at_hospital);
      setReadMoreText(rp.description);
      console.log('RESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', JSON.stringify(rp));
      setLoader(false);
    } else {
      setLoader(false);
      // setBookingCancalpopup(true);
    }
  };

  console.log(JSON.stringify(dataList), 'DATALIST IN RESCHEDULE');

  const onCallTermsApi = async () => {
    const response = await getTermsApi();
    if (isResponseIsValid(response)) {
      setTermsData(response.data.data);
      // setLoading(false);
    }
  };
  const onCancelBookingEvent = () => {
    let profile_info = profile_information;
    let property_input = {
      "Mobile number": profile_info.mobile_number,
      "Age": profile_info.age,
      "Gender": profile_info.gender,
      "Relationship": profile_info.relationship,
      "Is Primary User": profile_info.is_primary_user,
      "Consultation type": item?.slot_details[0]?.slot_type, //Online/Offline
      "Payment amount": dataList.amount,
      "Hospital Location": dataList.unit_details?.address,
      "Hospital unit": dataList.unit_details?.name,
      "Date selected": formatDateTime(dataList?.slot_date, dataList?.slot_time),
      "Time slot": dataList?.slot_time,
      "Patient name": dataList?.patient_name,
      "Cancellation reason": reason == 'Others (please specify)' ? feedBack : reason,
      "Payment option": pay_at_hospital === 1 ? 'Pay at hospital' : 'Payment done'
    }
    console.log('oncancel event input--->', property_input)
    // mixpanel.track(mixpanel_event.CANCELLATION, property_input)
  }

  const getReason = async () => {
    const response = await getReasonAPICall();
    if (isResponseIsValid(response)) {
      setReasonList(response.data?.data);
    }
  };

  const cancelBookApiCall = async () => {
    setLoader(true);
    console.log(item.booking_id, 'item.booking_id');
    const body = {
      _id: item.booking_id,
      reason: reason == 'Others (please specify)' ? feedBack : reason,
    };
    console.log('Request', body);
    const payment_Status = item.payment_status == 'success' ? 1 : 0;
    console.log('asdsad', payment_Status);
    console.log(body, 'UPDATE BOOKING BODY');

    try {
      const response = await cancelBookingAPICall(body, payment_Status);
      console.log(response, 'RESPONSE CANCELLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL');
      if (isResponseIsValid(response)) {
        console.log(response, 'response');
        setLoader(false);
        setFeedBack('');
        setReason('');
        onCancelBookingEvent()
        setTimeout(() => {
          if (upcomingListPage == false) {
            eventEmitter.emit('cancelBookingData', {
              isShowPopup: true,
              item: response?.data,
              pay_at_hos: pay_at_hospital,
            });
          }
          eventEmitter.emit('cancelBookingDataList', {
            isShowPopup: true,
            item: response?.data,
            pay_at_hos: pay_at_hospital,
          });
          //navigation.navigate('Home');
          history.push('/');
        }, 200);
      } else {
        setTimeout(() => {
          setLoader(false);
          setFeedBack('');
          setReason('');
          console.log(response?.data?.message, 'CANCEL ERROR');
          if (response?.data?.message) {
            setCancelErrorContent(response?.data?.message);
            setTimeout(() => {
              setCancelErrorPopup(true);
            }, 400);
          } else {
            eventEmitter.emit('webHookData', {
              isShowPopup: true,
              item: 'cancel',
            });
            setTimeout(() => {
              // navigation.navigate('MainScreens', {screen: 'HomeScreen'});
              history.push('/');
            }, 500);
            // snackBar(Headers.apiError);
          }
        }, 200);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  const onPressCheckout = () => {
    // navigation.navigate('RescheduleSlotDetails', {
    //   item: dataList,
    //   doctor_id: dataList._id.$oid,
    //   dataItem: item,
    //   pay_at_hospital: pay_at_hospital,
    // });
    console.log(dataList, "dataList")
    const queryParams = new URLSearchParams({      
      item: JSON.stringify(item),
      dataItem: JSON.stringify(dataItem),
      doctor_id: dataList._id.$oid,
      pay_at_hospital: pay_at_hospital,
    }).toString();

    history.push(`/rescheduleappointment?${queryParams}`);
  };

  const onOkayPress = () => {
    setCancelErrorPopup(false);
    setTimeout(() => {
      onInitialApiCall();
    }, 400);
  }

  return (
    <Container sx={{ p: 0, margin: 0 }}>
      <Header />
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, margin: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)', }}>
        <Avatar
          src={item.photo}
          alt="Doctor Image"
          sx={{ width: 50, height: 55, marginRight: 2, ml: 4, mt: 1, }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '16px' }}>{item.first_name}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(item.designation) ? item.designation.join(', ') : item.designation}</Typography>
        </Box>
        <Box sx={{ ml: 8 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{item.default_specialization}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {item.slot_range}</Typography>
        </Box>
        <Box sx={{ ml: 12 }}>
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{item.languages ?
            (Array.isArray(item.languages) ? item.languages.join(', ') : item.languages) :
            'Tamil, English'}
          </Typography>
          {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {}</Typography> */}
        </Box>
      </Box>
      {dataList && (
        <Box display="flex" flexDirection='column' alignItems="left" mb={2} ml={8}>
          <Button
            onClick={() => window.history.back()}
            sx={{
              marginTop: '10px', marginBottom: 0.5, marginLeft: '0px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none', fontSize: '17px', justifyContent: 'flex-start',
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px transparent`, // Keeps the focus ring on active state
              },
            }}
          >
            &lt; {item.booking_type === 'Offline' ? 'Hospital Visit' : 'Video Consultation'}
          </Button>
          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>
            {`${date}, ${dataItem?.slot_details[0]?.slot_start_time}`}
          </Typography>

          {/* <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOnIcon color="primary" sx={{ mr: 2 }} />
            <Box>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>
                {dataList?.unit_details?.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Poppins' }}>
                {dataList?.unit_details?.address}
              </Typography>
            </Box>
          </Paper> */}

          <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '16px', mt: 2 }}>
            Patient Details
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
              Name
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px', mt: 2 }}>
              {dataList?.patient_name}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
              Notes to doctor
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins' , color: COLORS.textColor, fontSize: '12px', mt: 2 }}>
              {dataList?.notes}
            </Typography>
          </Box>

          <Box display="flex" justifyContent='space-between' flexDirection='row' backgroundColor='#DCDCDC33' border='0px solid #00000029' borderRadius={1} width={{ xs: '20%', md: '20%' }} height='10%' boxShadow='0px 2px 4px rgba(0, 0.1, 0.1, 0.1) #00000029' mb={2} mt={2}>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
              {pay_at_hospital === 1 ? 'To pay' : 'Paid'}
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px' }}>
              Rs. {dataList?.amount}
            </Typography>
          </Box>
            {item.booking_type === 'Online' && (
          <Box sx={{ mb: 2 }}>
            <ErrorOutlineIcon />
            <Typography variant="subtitle2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', mt: 2 }}>
            You will receive a reminder 15 minutes before on the day of the call. Link for your call will be available here clicking on which will take you to the video consultation.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained"
              sx={{
                backgroundColor: '#962067',
                color: '#fff',
                p: 1,
                borderRadius: 8,
                textTransform: 'none',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                mt: 2,
                width: '20%',
                '&:hover': {
                  backgroundColor: '#962067',
                  borderColor: '#ffe6f2',
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`,
                },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`,
                },
              }}
              onClick={handleRescheduleOpen}
            >
              Join Now
            </Button>
            </Box>
          </Box>
      )}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {dataList?.is_avail_for_reschedule ? (
            <>
            <Button variant="contained"
              sx={{
                backgroundColor: '#962067',
                color: '#fff',
                p: 1,
                borderRadius: 8,
                textTransform: 'none',
                justifyContent: 'center',
                fontFamily: 'Poppins',
                mt: 2,
                width: '20%',
                '&:hover': {
                  backgroundColor: '#962067',
                  borderColor: '#ffe6f2',
                },
                ':focus': {
                  outline: 'none',
                  boxShadow: `0 0 0 2px transparent`,
                },
                ':active': {
                  boxShadow: `0 0 0 2px transparent`,
                },
              }}
              onClick={handleRescheduleOpen}
            >
              Reschedule
            </Button>
            </>
          ) : ( null )} 
          {dataList?.is_avail_for_cancellation ? (
            <>
            <Button variant="outlined" sx={{
              borderColor: '#962067',
              color: '#962067',
              p: 1,
              borderRadius: 8,
              textTransform: 'none',
              justifyContent: 'center',
              fontFamily: 'Poppins',
              mt: 2,
              width: '20%',
              ml: 1,
              '&:hover': {
                backgroundColor: '#ffffff',
                borderColor: '#ffe6f2',
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`,
              },
              ':active': {
                boxShadow: `0 0 0 2px transparent`,
              },
            }}
              onClick={handleCancelOpen}
            >
              Cancel appointment
            </Button>
            </>
          ) : ( null )}
          </Box>

          {/* Reschedule Popup */}
          <Modal open={rescheduleOpen} onClose={handleRescheduleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    // display: 'flex',
                    // flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
                        
                        <IconButton onClick={handleCloseDelete} sx={{ alignSelf: 'center', mt: 0, mb: 0, ml: 23, mt: 4, backgroundColor: 'red', justifyContent:'center', color: 'white'}}>
                            <CloseIcon />
                        </IconButton>
                        
                    </Box> */}
                    <Typography variant="h6" sx={{ mt: 2, ml: 2, mt: 2, textAlign: 'center' }} fontFamily='Poppins' color={COLORS.textColor} gutterBottom>Reschedule</Typography>
                    {/* <Typography variant="body2" color={COLORS.textColor} fontSize='14px' fontFamily='Poppins'>Delete</Typography> */}
                    <Typography variant="body2" color={COLORS.textColor} fontSize='12px' sx={{ mt: 2, ml: 2, mt: 2, mb: 2, textAlign: 'center' }} fontFamily='Poppins'>"Do you want to reschedule your appointment?"</Typography>
                    {/* <StyledTextField
                        label="File Name"
                        value={rename_file}
                        // onChange={(e) => validateName(e.target.value)}
                        onChange={(e) => setRenameFile(e.target.value)}
                        variant="outlined"
                        fullWidth margin="normal"
                        required
                    /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button variant="outlined"
                            sx={{
                                marginTop: '10px',
                                mb: 4,
                                ml: 2,
                                mr: 2,
                                borderRadius: '20px',
                                backgroundColor: COLORS.whiteColor,
                                borderColor: '#962067', // Assuming you want the border to match the primary color
                                color: COLORS.blackColor, // Text color
                                // fontSize: '16px', // Change to desired font size
                                fontFamily: 'Poppins', // Change to desired font weight
                                textTransform: 'none',
                                // boxShadow: '0px 2px 4px #962067',                                
                                '&:hover': {
                                    backgroundColor: '#962067',
                                    // color: '#939598',
                                    // borderColor: '#ffe6f2'
                                },
                            }}
                            onClick={handleRescheduleClose}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained"
                            sx={{
                                marginTop: '10px',
                                mb: 4,
                                // ml: 2,
                                mr: 3,
                                borderRadius: '20px',
                                backgroundColor: '#962067',
                                borderColor: '#962067', // Assuming you want the border to match the primary color
                                color: COLORS.whiteColor, // Text color
                                // fontSize: '16px', // Change to desired font size
                                fontFamily: 'Poppins', // Change to desired font weight
                                textTransform: 'none',
                                boxShadow: '0px 2px 4px #962067',
                                '&:hover': {
                                    backgroundColor: '#962067',
                                    // color: '#939598',
                                    borderColor: '#ffe6f2'
                                },
                            }}
                            onClick={() => onPressCheckout()}
                        >
                            Yes, Reschedule
                        </Button>
                    </Box>
                </Box>
            </Modal>

          {/* Cancel Popup */}
          <Modal open={cancelOpen} onClose={handleCancelClose}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: '70%', md: '50%' },
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
              maxHeight: '80%',
              overflowY: 'auto'
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Cancel Appointment</Typography>
                <IconButton onClick={handleCancelClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to cancel this appointment?
              </Typography>
              <Button variant="contained" color="primary" fullWidth onClick={handleCancelClose}>
                Confirm Cancellation
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </Container>
  );
};

export default AppointmentPage;
