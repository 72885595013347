import React, {useState} from 'react';
import { Box, Typography, Button, IconButton, Tabs, Tab } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlaceIcon from '@mui/icons-material/Place';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { COLORS } from '../constants/Theme';
import { useHistory } from 'react-router-dom';
import { NewDateFormatting, TimeFormatting, bottomSpacing } from '../utils/helpers';
import {mhcBookingDetailView, reScheduleBooking, upcomingEvents} from '../utils/apiCalls';
import {isResponseIsValid, snackBar} from '../utils/helpers';

const ConsultationCard = ({ type, date, time, doctor, patient, location, status, fee, tab, booking_id, event, paymentStatus }) => {
  const [joinNowErrorPopup, setJoinNowErrorPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const callerid = "3hasjhdidb8735";
  const handleVideoCall = async (id, event) => {
    setLoader(true);
    try {
      const response = await upcomingEvents(1, 100);
      if (isResponseIsValid(response)) {
        response.data.data.map(e => {
          if (e.booking_id === id) {
            if (callerid !== null) {
              // if (e.caller_id !== null) {
              setLoader(false);
              setTimeout(() => {
                const queryParams = new URLSearchParams({
                  item: JSON.stringify(event),
                  callerid: callerid,
                }).toString();
          
                history.push(`/videoscreen?${queryParams}`);
              }, 400);
            } else {
              setLoader(false);
              setTimeout(() => {
                setJoinNowErrorPopup(true);
              }, 1000);
            }
          }
        });
      } else {
        setLoader(false);
        setTimeout(() => {
          snackBar(JSON.stringify(response.data));
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  }

  const handleReschedule = (item) => {
    rescheduleUpcomingEventsApiCall(item);
  }
  const rescheduleUpcomingEventsApiCall = async item => {
    setLoader(true);
    try {
      const response = await upcomingEvents(1, 100);
      if (isResponseIsValid(response)) {
        response.data.data.map(e => {
          if (e.booking_id === item.booking_id) {
            if (e.is_reschedule == true) {
              if(e.booking_type === "mhc"){
                // MHCBookingDetailViewApiCall(item);
              }else{
                RescheduleonApiCall(item);
              }
            } else {
              setLoader(false);
              setTimeout(() => {
                // setRescheduleErrorPopup(true);
              }, 1000);
            }
          }
        });
      } else {
        setLoader(false);
        setTimeout(() => {
          snackBar(JSON.stringify(response.data));
        }, 400);
      }
    } catch (err) {
      setLoader(false);
      setTimeout(() => {
        snackBar(JSON.stringify(err));
      }, 400);
    }
  };

  const RescheduleonApiCall = async item => {
    setLoader(true);
    const response = await reScheduleBooking(item.booking_id);
    if (isResponseIsValid(response)) {
      setLoader(false);
      const rp = response.data.data;
      console.log('rpppppppp', response.data.data)
      // navigation.navigate('RescheduleSlotDetails', {
      //   item: rp,
      //   doctor_id: rp._id.$oid,
      //   dataItem: item,
      //   pay_at_hospital: rp.pay_at_hospital,
      // });
      const queryParams=new URLSearchParams({
        item: JSON.stringify(rp),
        doctor_id: rp._id.$oid,
        dataItem: JSON.stringify(item),
        pay_at_hospital: rp.pay_at_hospital,
    }).toString();
    history.push(`/reschedule?${queryParams}`);
    } else {
      setLoader(false);
    }
  };

  return (
    <Box sx={{
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '16px',
      marginBottom: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      //   boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
      mb: 1,
      '&:hover': {
        boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
      },
    }}>

      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', padding: 1 }}>
          {type === 'video' ? <VideoCallIcon sx={{ color: COLORS.primaryColor }} /> : <LocalHospitalIcon sx={{ color: COLORS.primaryColor }} />}
          {type === 'video' ? 'Video Consultation' : 'Hospital Visit'}
        </Typography>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
          {`${date}, ${time}`}
        </Typography>
        {location && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            {/* <PlaceIcon sx={{ marginRight: '8px', color: COLORS.primaryColor }} /> */}
            <Typography variant="body2" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', padding: 1 }}>
              <PlaceIcon sx={{ marginRight: '8px', color: COLORS.primaryColor }} />
              {location}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" sx={{ fontWeight: 'normal', fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '14px', }}>
          Dr. {doctor}
        </Typography>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
          Patient: {patient}
        </Typography>
        {fee && (
          <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.placeholderColor, fontSize: '12px' }}>
            {/* {`Paid: ${fee}`} */}
          </Typography>
        )}
      </Box>
      {tab == 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {status=='active' && paymentStatus!='pending' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 10 }}>
              {/* <Button variant="outlined" sx={{ marginBottom: '8px' }}>
                    Reschedule
                </Button>
                <Button variant="outlined" color="error" sx={{ marginBottom: '8px' }}>
                    Cancel
                </Button> */}

              <Typography
                component="a"
                onClick={() => handleReschedule(event)}
                cursor= 'pointer'
                sx={{ marginBottom: '8px', textDecoration: 'none', color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '12px' }}
              >
                Reschedule
              </Typography>
              <Typography
                component="a"
                href="#"
                sx={{ marginBottom: '8px', textDecoration: 'none', color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '12px' }}
              >
                Cancel
              </Typography>

            </Box>
          )}
          {status=='active' && paymentStatus=='pending' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 10 }}>
              {/* <Button variant="outlined" sx={{ marginBottom: '8px' }}>
                    Reschedule
                </Button>
                <Button variant="outlined" color="error" sx={{ marginBottom: '8px' }}>
                    Cancel
                </Button> */}

              <Typography
                component="a"
                onClick={() => handleReschedule(event)}
                sx={{ marginBottom: '8px', textDecoration: 'none', color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '12px' }}
              >
                Reschedule
              </Typography>
              <Typography
                component="a"
                href="#"
                sx={{ marginBottom: '8px', textDecoration: 'none', color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '12px' }}
              >
                Cancel
              </Typography>
              <Typography
                component="a"
                href="#"
                sx={{ marginBottom: '8px', textDecoration: 'none', color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '12px' }}
              >
                Pay now
              </Typography>

            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {status === 'active' && (
              <Button
                sx={{
                  backgroundColor: '#00FF221A',
                  color: '#13C54E',
                  fontFamily: 'Poppins',
                  minWidth: '100px',
                  fontSize: '12px',
                  padding: '1px 14px',
                  textTransform: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  mb: 1,
                  '&:hover': {
                    backgroundColor: '#00FF221A',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px #00FF221A`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px #00FF221A`, // Keeps the focus ring on active state
                  },
                }}
              >
                Active
              </Button>
            )}
            {status === 'cancelled' && (
              <Button
                sx={{
                  backgroundColor: '#FF00001A',
                  color: '#C51313',
                  fontFamily: 'Poppins',
                  minWidth: '100px',
                  fontSize: '12px',
                  padding: '1px 14px',
                  textTransform: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: '#FF00001A',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px ##FF00001A`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px #FF00001A`, // Keeps the focus ring on active state
                  },
                }}
              >
                Cancelled
              </Button>
            )}

            {status === 'active' && type === 'video' && (
              <Button
                onClick={() => handleVideoCall(booking_id, event)}
                sx={{
                  backgroundColor: COLORS.primaryColor,
                  color: 'white',
                  fontFamily: 'Poppins',
                  minWidth: '100px',
                  fontSize: '12px',
                  padding: '1px 14px',
                  textTransform: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  '&:hover': {
                    backgroundColor: COLORS.primaryColor,
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                  },
                }}
              >
                Join Now
              </Button>
            )}
            {status === 'on hold' && (
              <Button
                sx={{
                  backgroundColor: '#E6E6E6',
                  color: '#58595B',
                  fontFamily: 'Poppins',
                  minWidth: '100px',
                  fontSize: '12px',
                  padding: '1px 14px',
                  textTransform: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  mb: 1,
                  '&:hover': {
                    backgroundColor: '#E6E6E6',
                  },
                  ':focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 2px #E6E6E6`, // Change this to `transparent` if you want no color
                  },
                  // ':hover': {
                  //   backgroundColor: COLORS.primaryColor,
                  // },
                  ':active': {
                    boxShadow: `0 0 0 2px #E6E6E6`, // Keeps the focus ring on active state
                  },
                }}
              >
                On hold
              </Button>
            )}
            {status === 'cancelled' && fee && (
              <Button variant="contained" color="secondary">
                Pay Now
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mb: 1 }}>

          <Button
            sx={{
              backgroundColor: COLORS.primaryColor,
              color: '#ffffff',
              fontFamily: 'Poppins',
              minWidth: '100px',
              fontSize: '12px',
              padding: '1px 14px',
              textTransform: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '15px',
              '&:hover': {
                backgroundColor: COLORS.primaryColor,
              },
              ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
              },
              // ':hover': {
              //   backgroundColor: COLORS.primaryColor,
              // },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}
          >
            Repeat appointment
          </Button>
        </Box>
      )}
    </Box>
  );
};


export default ConsultationCard;
