import React, { useEffect } from 'react';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { useHistory, useLocation } from 'react-router-dom';

const VideoCallScreen = ({ }) => {
  const history = useHistory();
//   const data = location.state?.data || {};
//   const { patient_id, caller_id, patient_name } = data;

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
// const item = JSON.parse(queryParams.get('item'));
const caller_id = queryParams.get('callerid');
const patient_id = "deecc716-8338-4a4f-bd8e-c62a9bb60d0f";
const patient_name = "Pushpalakshmi";

  useEffect(() => {
    if (!patient_id || !caller_id || !patient_name) {
      console.error("Missing required parameters.");
      return;
    }

    const appID = 167959465; // Your app ID
    const server = "wss://webrtc-api.zego.im/ws"; // Zego WebRTC server URL
    const token = ""; // Generate a token from your server

    const zg = new ZegoExpressEngine(appID, server);

    zg.loginRoom(caller_id, token, {
      userID: patient_id,
      userName: patient_name,
    })
      .then(() => {
        zg.startPublishingStream("streamID");
        zg.startPlayingStream("streamID", document.getElementById('localVideo'));
      })
      .catch((error) => {
        console.error('Failed to join the room:', error);
      });

    return () => {
      zg.logoutRoom(caller_id);
    };
  }, [patient_id, caller_id, patient_name]);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <video id="localVideo" autoPlay muted style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default VideoCallScreen;
