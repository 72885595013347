import React, { useState } from 'react';
import { Container, Card, CardContent, CardHeader, Avatar, Typography, Grid, Button, Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Header from './HeaderNew';
import { useTheme } from '@mui/material/styles';
import { COLORS } from '../constants/Theme';
import { useLocation, useHistory } from 'react-router-dom';
import {isResponseIsValid, snackBar} from '../utils/helpers';
import {
  getSecondaryProfileID,
  setSecondaryProfileID,
} from '../utils/LocalStorage';
import {useDispatch, useSelector} from 'react-redux';
import {
  currentProfileName,
  currentProfileRawData,
  profileInformation,
} from '../store/actions/homeActions';
import {
  familyMembersList,
  getPrimaryData,
  getUserDetails,
  removeProfile,
} from '../utils/apiCalls';


const ManageFamily = () => {
  const theme = useTheme();
  const location=useLocation();
  const [removePopup, setRemovePopup] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const userProfilesData = useSelector(
    state => state?.homeReducer?.currentProfileRawData,
  );

  const queryParams = new URLSearchParams(location.search);
    // const item = queryParams.get('item');
    const data = JSON.parse(queryParams.get('data'));
    console.log('itemmmmm', data);

    const handleCloseDelete = () => {
      setRemovePopup(false);
      // setFileName('');
      // setRenameFile('');
      // setSelectedRecord(null);
  };

  const onDeleteAPI = () => {
    setRemovePopup(false);
    onPressRemove();
  }

  const onPressRemove = async () => {
    let body = {
      profile_id: data.profile_id,
    };
    console.log('PRofile', body);
    // setLoader(true)
    getSecondaryProfileID('Secondary_Profile_ID').then(async res => {
      let json = JSON.parse(res);
      const response = await removeProfile(body);
      if (isResponseIsValid(response)) {
        if (data.profile_id == json) {
          if (
            userProfilesData[0].app_profile_id !== null ||
            userProfilesData[0].app_profile_id !== undefined
          ) {
            setSecondaryProfileID(
              'Secondary_Profile_ID',
              JSON.stringify(userProfilesData[0].app_profile_id),
            )
              .then(() => {
                // DeviceEventEmitter.emit('reloadUpload');
                dispatch(profileInformation(userProfilesData[0]));

                dispatch(currentProfileName(userProfilesData[0].name));
                // navigation.goBack();
                // route.params.reloadData();
                history.goBack();
              })
              .catch(err => {
                setLoader(false)
                // snackBar(JSON.stringify(err));
              });
          } else {
            setLoader(false)
            history.goBack();
            // route.params.reloadData();
          }
        } else {
          setLoader(false)
          history.goBack();
          // route.params.reloadData();
        }
      }
    });
  };

  return (
    <Container style={{ padding: 0, margin: 0, backgroundColor: '#fff', borderRadius: '8px' }}>
      <Header />
      <Box display="flex" alignItems="center" mb={2}>
        {/* <ArrowBackIosIcon style={{ marginRight: '0.5rem', cursor: 'pointer' }} />
        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>My Account</Typography> */}
      <Button
        onClick={() => window.history.back()}
        sx={{ marginTop: '20px', marginBottom: 2, marginLeft: '50px', fontSize: '16px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none' }}
      >
        &lt; Manage Family Profile
      </Button>
      </Box>
      
      <Card sx={{ backgroundColor: theme.palette.background.default, boxShadow: 'none', marginLeft: '50px' }}>
        <CardContent>
          <Box>
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>{data.name}</Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom sx={{ fontFamily: 'Poppins', color:COLORS.placeholderColor }}>{data.gender}</Typography>
          {/* <Chip label="Sister" style={{ marginBottom: '1rem', backgroundColor: '#ffe6f2' }} /> */}
          </Box>
          <Grid container spacing={3} sx={{ mb: 2, mt: 2}}>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>UHID:</Typography>
              <Typography variant="body1">-</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>ABHA ID:</Typography>
              <Typography variant="body1">-</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>DOB:</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>1999-01-07</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>Pincode:</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>{data.pincode}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>Mobile number:</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>{data.mn}</Typography>
            </Grid>
            <Grid item xs={6} >
              <Typography variant="body2" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>WhatsApp number:</Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>{data.whtap_no}</Typography>
            </Grid>
          </Grid>
          
          <Box mt={3} display="flex" justifyContent="center">
        <Button
          variant="contained"
          // color="secondary"
          onClick={() => setRemovePopup(true)}
          sx={{ textTransform: 'none', fontFamily: 'Poppins', borderColor: COLORS.primaryColor, color: COLORS.primaryColor, backgroundColor: '#FFFFFF', borderRadius: '5px' }} // Remove uppercase
        >
          Remove Profile
        </Button>
      </Box>
      </CardContent>
      </Card>
      <Modal open={removePopup} onClose={handleCloseDelete}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    // display: 'flex',
                    // flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
                        
                        <IconButton onClick={handleCloseDelete} sx={{ alignSelf: 'center', mt: 0, mb: 0, ml: 23, mt: 2, backgroundColor: 'red', justifyContent:'center' }}>
                            <CloseIcon />
                        </IconButton>
                        
                    </Box>
                    <Typography variant="h6" sx={{ mt: 2, ml: 2, mt: 2, textAlign: 'center' }} fontFamily='Poppins' color={COLORS.textColor} gutterBottom>Delete</Typography>
                    {/* <Typography variant="body2" color={COLORS.textColor} fontSize='14px' fontFamily='Poppins'>Delete</Typography> */}
                    <Typography variant="body2" color={COLORS.textColor} fontSize='12px' sx={{ mt: 2, ml: 2, mt: 2, textAlign: 'center' }} fontFamily='Poppins'>Are you sure you want to remove ${data.name} from linked accounts?</Typography>
                    {/* <StyledTextField
                        label="File Name"
                        value={rename_file}
                        // onChange={(e) => validateName(e.target.value)}
                        onChange={(e) => setRenameFile(e.target.value)}
                        variant="outlined"
                        fullWidth margin="normal"
                        required
                    /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button variant="outlined"
                            sx={{
                                marginTop: '10px',
                                mb: 2,
                                ml: 2,
                                mr: 2,
                                borderRadius: '20px',
                                backgroundColor: COLORS.whiteColor,
                                borderColor: '#962067', // Assuming you want the border to match the primary color
                                color: COLORS.blackColor, // Text color
                                // fontSize: '16px', // Change to desired font size
                                fontFamily: 'Poppins', // Change to desired font weight
                                textTransform: 'none',
                                // boxShadow: '0px 2px 4px #962067',                                
                                '&:hover': {
                                    backgroundColor: '#962067',
                                    // color: '#939598',
                                    // borderColor: '#ffe6f2'
                                },
                            }}
                            onClick={handleCloseDelete}
                        >
                            No
                        </Button>
                        <Button variant="contained"
                            sx={{
                                marginTop: '10px',
                                mb: 2,
                                // ml: 2,
                                mr: 3,
                                borderRadius: '20px',
                                backgroundColor: '#962067',
                                borderColor: '#962067', // Assuming you want the border to match the primary color
                                color: COLORS.whiteColor, // Text color
                                // fontSize: '16px', // Change to desired font size
                                fontFamily: 'Poppins', // Change to desired font weight
                                textTransform: 'none',
                                boxShadow: '0px 2px 4px #962067',
                                '&:hover': {
                                    backgroundColor: '#962067',
                                    // color: '#939598',
                                    borderColor: '#ffe6f2'
                                },
                            }}
                            onClick={() => onDeleteAPI()}
                        >
                            Yes, Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
    </Container>
  );
};

export default ManageFamily;
