//Login
export const loginResponse = 'LoginResponse';
export const otpResponseData = 'OTPResponse';

//Profile
export const profileImageUrl = 'ProfileImageUrl';
export const profileImageUrlSaved = 'ProfileImageUrlSaved';
export const otpPopupStatus = 'otpPopupStatus';
export const verifyUserData = 'verifyUserData';
export const verifyUserDataUnselected = 'verifyUserDataUnselected';
export const verifyDetailsSingle = 'verifyDetailsSingle';
// Static Data
export const staticData = 'StaticData';

//Home 
export const currentRegion = 'CurrentRegion';
export const currentProfile = 'CurrentProfile';
export const currentProfileName = 'CurrentProfileName';
export const currentProfileRawData = 'currentProfileRawData';
export const upcomingListPageShown = 'upcomingListPageShown';

//Booking
export const patientName = 'PatientName';
export const patientId = 'PatientId';
export const notes = 'notes';
export const amount = 'Amount';
export const consultMode = 'ConsultMode';
export const doctorDetails = 'DoctorDetails';
export const slotDetails = 'SlotDetails';
export const slotAddress = 'SlotAddress';
export const slotStartTime = 'SlotStartTime';
export const mhcBookingConfirmedEvent = 'MhcBookingConfirmedEvent';

// Family Type
export const familyReload = 'FamilyReload';
export const unitName = 'UnitName';
export const mobNumber = 'mobNumber';
export const customerCareNumber = 'customerCareNumber';
export const customerCareCallNo = 'customerCareCallNo';


// Record Action

export const UPLOAD_RECORD_LIST = 'UPLOAD_RECORD_LIST';

export const EMPTY_UPLOAD_RECORD_LIST = 'EMPTY_UPLOAD_RECORD_LIST';

export const EMPTY_MY_UPLOAD_SECTION_RECORD_LIST = 'EMPTY_MY_UPLOAD_SECTION_RECORD_LIST';

export const REMOVE_UPLOADED_ITEM = 'REMOVE_UPLOADED_ITEM';

export const UPLOAD_LOADING = 'UPLOAD_LOADING';

export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';


export const MY_UPLOAD_RECORD_LIST = 'MY_UPLOAD_RECORD_LIST';

export const SELECTED_DOCUMENT = 'SELECTED_DOCUMENT'

export const MY_UPLOAD_RECORD_EXTRA_LIST = 'MY_UPLOAD_RECORD_EXTRA_LIST';

export const MY_UPLOAD_LOADING = 'MY_UPLOAD_LOADING';

export const MY_UPLOAD_FAILURE = 'MY_UPLOAD_FAILURE';

export const MY_UPLOAD_TOGGLE_MENU = 'MY_UPLOAD_TOGGLE_MENU';

export const MY_UPLOAD_RENAME = 'MY_UPLOAD_RENAME';

export const MY_UPLOAD_DELETE = 'MY_UPLOAD_DELETE';

export const DELETE_MY_UPLOAD_FILE = 'DELETE_MY_UPLOAD_FILE';

export const HOSPITAL_RECORD = 'HOSPITAL_RECORD';

export const HOSPITAL_RECORD_DATA = 'HOSPITAL_RECORD_DATA';

export const HOSPITAL_RECORD_HEADER_NAME = 'HOSPITAL_RECORD_HEADER_NAME';

export const HOSPITAL_RECORD_TOGGLE_MENU = 'HOSPITAL_RECORD_TOGGLE_MENU';

export const HOSPITAL_RECORD_EXTRA_DATA = 'HOSPITAL_RECORD_EXTRA_DATA';


// Booking Upload Action 


export const BOOKING_UPLOADED_FILE_LIST = 'BOOKING_UPLOADED_FILE_LIST';

export const REMOVE_BOOKING_UPLOADED_ITEM = 'REMOVE_BOOKING_UPLOADED_ITEM';

export const EMPTY_BOOKING_UPLOADED_FILE_LIST = 'EMPTY_BOOKING_UPLOADED_FILE_LIST';


export const MY_UPLOAD_BOOKING_RECORD_LIST = 'MY_UPLOAD_BOOKING_RECORD_LIST';


export const MY_UPLOAD_BOOKING_RECORD_EXTRA_LIST = 'MY_UPLOAD_BOOKING_RECORD_EXTRA_LIST';

export const MY_UPLOAD_BOOKING_SELECTION = 'MY_UPLOAD_BOOKING_SELECTION';

export const MY_UPLOAD_BOOKING_SELECTED_CLEAR = 'MY_UPLOAD_BOOKING_SELECTED_CLEAR';

export const HOSPITAL_RECORD_BOOKING_EXTRA_DATA = 'HOSPITAL_RECORD_BOOKING_EXTRA_DATA';

export const HOSPITAL_RECORD_BOOKING_DATA = 'HOSPITAL_RECORD_BOOKING_DATA';

export const  HOSPITAL_RECORD_BOOKING_SELECTION = 'HOSPITAL_RECORD_BOOKING_SELECTION';

export const EMPTY_UPLOAD_BOOKING_RECORD_LIST = 'EMPTY_UPLOAD_BOOKING_RECORD_LIST';

export const MY_UPLOAD_DUPLICATE_FILE_LIST = 'MY_UPLOAD_DUPLICATE_FILE_LIST';


// MHC Actions

export const PACKAGE_DETAILS = 'PACKAGE_DETAILS';

export const PACKAGE_FEE = 'PACKAGE_FEE';

export const MHC_DATE = 'MHC_DATE';

export const MHC_PATIENT_NAME = 'MHC_PATIENT_NAME';

export const MHC_PATIENT_ID = 'MHC_PATIENT_ID';

export const MHC_NOTES = 'MHC_NOTES';

export const MHC_UNIT_DATA = 'MHC_UNIT_DATA';

export const MHC_PATIENT_DATA = 'MHC_PATIENT_DATA';

export const MHC_CHOSEN_DATE = 'MHC_CHOSEN_DATE'
// NOTIFICATION

export const badge_visible = 'BadgeVisible';

// RECORD SEARCH API
export const SEARCH_MY_UPLOAD_RECORD_LIST = 'SEARCH_MY_UPLOAD_RECORD_LIST';

export const SEARCH_MY_UPLOAD_TOGGLE_MENU = 'SEARCH_MY_UPLOAD_TOGGLE_MENU';

export const SEARCH_MY_UPLOAD_DELETE = 'SEARCH_MY_UPLOAD_DELETE';

export const SEARCH_MY_UPLOAD_RENAME = 'SEARCH_MY_UPLOAD_RENAME';

export const SEARCH_HOSPITAL_RECORDS = "SEARCH_HOSPITAL_RECORDS";

export const PROFILE_INFORMATION = "PROFILE_INFORMATION";

// CheckOut Success Data

export const CHECKOUT_BOOKING_SUCCESS = "CHECKOUT_BOOKING_SUCCESS";
export const CHECKOUT_MHC_SUCCESS = "CHECKOUT_MHC_SUCCESS";

export const CHECKOUT_BOOKING__CLEAR_SUCCESS = "CHECKOUT_BOOKING__CLEAR_SUCCESS";
export const CHECKOUT_MHC_CLEAR_SUCCESS = "CHECKOUT_MHC_CLEAR_SUCCESS";

// CodePush

export const CODEPUSH_RESTART_STATUS = "CODEPUSH_RESTART_STATUS";
