import React, { useState } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Header from './HomeHeader';
import { COLORS } from '../constants/Theme';

const YourComponent = ({ records, handleOpen }) => {
    const [history, setHistory] = useState([]);
  return (
    <Box sx={{ padding: 0 }}>
        <Header />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px' }}>
          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderRadius: '50px', boxShadow: 1, backgroundColor: '#f5f5f5', alignItems: 'center', mb: 2, height: '30px' }}>
            <InputBase
              placeholder="Search for health records"
              sx={{ ml: 2, flex: 1, fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}
            />
            <IconButton sx={{
              p: '10px', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}>
              <SearchIcon sx={{ color: COLORS.primaryColor }} />
            </IconButton>
          </Box> */}
          </Box>
        <Box display="flex" alignItems="center" mb={2}>
        {/* <ArrowBackIosIcon style={{ marginRight: '0.5rem', cursor: 'pointer' }} />
        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color:COLORS.textColor }}>My Account</Typography> */}
      <Button
        onClick={() => window.history.back()}
        sx={{ marginTop: '20px', marginBottom: 2, marginLeft: '50px', fontSize: '16px', color: COLORS.textColor, fontFamily: 'Poppins', textTransform: 'none' }}
      >
        &lt; Payment History
      </Button>
      </Box>
    <Box textAlign="center" my={4}>
      {history.length === 0 ? (
        <>
          <Typography variant="body2" fontFamily="Poppins" color={COLORS.textColor} gutterBottom>
            No payment history found
          </Typography>
          
        </>
      ) : (
        <List>
          {records.map((record, index) => (
            <ListItem key={index} sx={{ borderBottom: '1px solid #ddd', padding: '10px 0' }}>
              <ListItemText
                primary={record.name}
                secondary={new Date(record.date).toLocaleString()}
                primaryTypographyProps={{ fontFamily: 'Poppins', fontWeight: 'bold' }}
                secondaryTypographyProps={{ fontFamily: 'Poppins' }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
    </Box>
  );
};

export default YourComponent;
