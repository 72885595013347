// SpecialtiesList.js
import React, { useEffect, useState } from 'react';
// import styles from './Doctor.styles';
// import Doctor from './Doctor';
import { Card, CardContent, Typography, Grid, Box, Container, Avatar, Button, InputBase, IconButton, Select, MenuItem, InputAdornment, FormControl, OutlinedInput, Modal } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import Images from '../constants/Images';
import Header from './HomeHeader';
import { COLORS, FONTS } from '../constants/Theme';
import { useHistory } from 'react-router-dom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import axios from 'axios';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import {
  addFeedback,
  getFeedbackList,
  getProfileApi,
  nearbyUnits,
  nearbyUnitsCoordinates,
  nearbyUnitsRegion,
  nearbyUnitsWithoutRegion,
  reScheduleBooking,
  specializationList,
  specializationList1,
  upcomingEvents,
  updateBooking,
  specializationListWithUnitID,
  doctorsListInConsultation,
  doctorsListInConsultationwithoutPagenation
} from '../utils/apiCalls';
import { isResponseIsValid, snackBar } from '../utils/helpers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const SpecialtiesDoctor = () => {
  const [showAll, setShowAll] = useState(false);
  const [showAllDoctors, setShowAllDoctors] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('Chennai - Mylapore');
  const [specialties, setSpecialities] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [showMoreSpecialties, setShowMoreSpecialties] = useState(false);
  const [showMoreDoctors, setShowMoreDoctors] = useState(false);
  const [spID, setSPID] = useState(10471000000018);
  const location = useLocation();
  const [showAll1, setShowAll1] = useState(false);
  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const [page_size, setPage_size] = useState(80);
  const [page, setPage] = useState(1);
  const [next_page, setNext_page] = useState(null);
  const displayedDoctors = showAll1 ? doctorsDetails : doctorsDetails.slice(0, 4);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [search, setSearch] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [doctor, setDoctor] = useState([]);
  // const [spec_name, setSpecName] = useState('');
  const [doctorListCount, setDoctorListCount] = useState('');
  const [activeSpecialty, setActiveSpecialty] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  // const selectedUnit = queryParams.get('selectedUnit');
  const unitAddres = queryParams.get('unitAddress');
  const unitId = queryParams.get('unitid');
  const city = queryParams.get('city');
  // const item = JSON.parse(queryParams.get('item'));
  // const data = JSON.parse(queryParams.get('data'));
  // const doctorSlot = JSON.parse(queryParams.get('doctorSlot'));
  const [unitAddress, setUnitAddress] = useState(unitAddres);
  const displayedSpecialties = showAll ? specialtiesList : specialtiesList.slice(0, 6);
  console.log(unitId, 'iddddddddd');
  const fetchToken = async () => {
    try {
      const tokenUrl = 'http://192.168.28.86/KAPP_INFOSHARE/token';

      const requestData = {
        grant_type: 'password',
        username: 'Kauvery',
        password: 'Kmc@123'
      };
      console.log('data :', requestData);
      const tokenResponse = await axios.post(tokenUrl, qs.stringify(requestData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log('url', tokenResponse);
      return tokenResponse.data.access_token;
    }
    catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  // Function to fetch location data using the token
  const fetchLocations = async () => {

    try {
      // const tokenUrl = 'http://192.168.28.86/KAPP_INFOSHARE/api/Values/GetUnit';
      const tokenUrl = 'http://localhost:1801/api/unit';
      const locationResponse = await axios.get(tokenUrl, {
        headers: {
          // 'Authorization': `Bearer ${token}`
        }
      });

      setDropdownOptions(locationResponse.data.Result);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchDoctors = async (city1, spID) => {
    const city = city1;
    const spid = spID;
    // console.log('cityuuuuuuuuuuuuu', city);
    // console.log('idddddddddd', spID);
    try {
      const response = await axios.get(`http://localhost:1801/api/doctors/`, { params: { city, spid } });
      // Handle the response as needed
      console.log('Response from POST API:', response.data);
      setDoctors(response.data);
    } catch (error) {
      console.error('Error sending selected unit to API:', error);
    }
  };

  const sendSelectedUnitToAPI = async (city1) => {
    const city = city1;
    // console.log('cityuuuuuuuuuuuuu', city);
    try {
      const response = await axios.get(`http://localhost:1801/api/specialities/`, { params: { city } });
      // Handle the response as needed
      console.log('Response from POST API:', response.data);
      setSpecialities(response.data);
    } catch (error) {
      console.error('Error sending selected unit to API:', error);
    }
  };

  const onClose = () => {
    setOpenPopup(false);
}

  useEffect(() => {
    const initializeData = async () => {
      // const fetchedToken = await fetchToken();
      // console.log('ksjhfoiudaogrequoIoj',fetchedToken);
      // setToken('ksdjfhwuetgsoe', fetchedToken);
      // if (fetchedToken) {
      fetchLocations();
      sendSelectedUnitToAPI(selectedUnit);
      fetchDoctors(selectedUnit, spID);
      combinedApiCall(unitId);
      // }
    };

    initializeData();
  }, []);

  const combinedApiCall = async (unitid) => {
    try {
      // setLoader(true);

      console.log('UNIT ID PASSED IN API', unitid);
      //   const specialtiesResponse = await specializationListWithUnitID(
      //     unitId,
      //     1,
      //     4,
      //   );

      //   if (isResponseIsValid(specialtiesResponse)) {
      //     setSpecialityCount(specialtiesResponse.data.total_count);
      //     setSpecialtiesData(specialtiesResponse.data.data);
      //   } else {
      //     if (specialtiesResponse?.data) {
      //       snackBar(specialtiesResponse?.data);
      //     } else {
      //       snackBar(Headers.apiError);
      //     }
      //   }

      const doctorsResponse = await doctorsListInConsultation(
        unitid,
        city,
        page,
        page_size
      );

      if (isResponseIsValid(doctorsResponse)) {
        if (page == 1) {
          setDoctorListCount(doctorsResponse.data.total_count);
          setDoctorsDetails(doctorsResponse.data.data);
          console.log('ddddddd', doctorsResponse.data.data);
        }
        else {
          setDoctorsDetails(prevDoctorsDetails => [
            ...prevDoctorsDetails,
            ...doctorsResponse.data.data,
          ]);
          console.log('eeeee', doctorsDetails);
        }
      } else {
        if (doctorsResponse?.data) {
          snackBar(doctorsResponse?.data);
        } else {
          snackBar(Headers.apiError);
        }
      }
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
      }, 400);
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
        setInitialLoader(false);
        snackBar(Headers.apiError);
      }, 500);

    }
  };

  useEffect(() => {
    specialtiesListApiCall(unitId, page, page_size, city);
  }, [city]);

  const specialtiesListApiCall = async (
    unitId,
    currentPage,
    pageSize,
    region,
  ) => {
    setLoader(true);
    try {
      const response = await specializationListWithUnitID(
        unitId,
        currentPage,
        pageSize,
        // region,
      );
      console.log('asdsadasd', unitId, region);
      console.log(JSON.stringify(response.data.data), 'response123');
      if (isResponseIsValid(response)) {
        //console.log(response.data.data, 'response');

        // setSpecialtiesList(response.data.data);
        if (currentPage == 1) {
          setSpecialtiesList(response.data.data);
          setNext_page(response.data.next_page);
        } else {
          setSpecialtiesList([...specialtiesList, ...response.data.data]);
          setNext_page(response.data.next_page);
        }

        setTimeout(() => {
          setInitialLoader(false);
          setLoader(false);
        }, 500);
      } else {
        setInitialLoader(false);
        setLoader(false);
        setTimeout(() => {
          if (response?.data) {
            snackBar(response?.data);
          } else {
            snackBar(Headers.apiError);
          }
        }, 400);
      }
    } catch (err) {
      setInitialLoader(false);
      setLoader(false);
      setTimeout(() => {
        snackBar(Headers.apiError);
      }, 400);
    }
  };

  const handleUnitChange = (unit) => {
    const selectedUnitName = unit;
    setSelectedUnit(selectedUnitName);
    sendSelectedUnitToAPI(unit);
    fetchDoctors(unit, spID);
    const selectedOption = dropdownOptions.find(option => option.UnitName === selectedUnitName);
    if (selectedOption) {
      setUnitAddress(selectedOption.Address);
      // console.log('adrrrr', unitAddress);
    } else {
      setUnitAddress('');
    }
  };
  console.log('uniiii', selectedUnit);

  const handleSpecialtyClick = async (specialtyId, unitId, spec_name, specialty) => {
    // setActiveSpecialty(specialtyId);
    // setDoctorsDetails('');
    console.log('iiiiii', specialtyId, unitId, spec_name);

    const queryParams = new URLSearchParams({
      dropdownOptions: dropdownOptions,
      selectedUnit: selectedUnit,
      unitAddress: unitAddress,
      unitid: unitId,
      city: city,
      spId: specialtyId,
      specName: spec_name
    }).toString();

    history.push(`/doctors?${queryParams}`);
  };

  const handleChange = (event) => {
    // console.log('inside');
    const selectedUnitName = event.target.value;
    setSelectedUnit(selectedUnitName);
    const selectedOption = dropdownOptions.find(option => option.UnitName === selectedUnitName);
    console.log('adrsssssssss', selectedOption);
    if (selectedOption) {
      setUnitAddress(selectedOption.Address);
      // setUnitid(selectedOption.UnitId);
    }
    // sendSelectedUnitToAPI(event.target.value);
  };

  const handleSlots = (selectedUnit, item) => {
    // history.push({
    //     pathname: '/appointmentbook',
    //     state: { selectedUnit, item },
    //   });
    const queryParams = new URLSearchParams({
      selectedUnit: selectedUnit,
      item: JSON.stringify(item),
      dropdownOptions: dropdownOptions,
      unitAddress: unitAddress,
      unitid: unitId
      // item: item,
    }).toString();

    history.push(`/appointmentbook?${queryParams}`);
  };

  const handleReq = (selectedUnit, item) => {
    // history.push({
    //     pathname: '/appointmentbook',
    //     state: { selectedUnit, item },
    //   });
    const queryParams = new URLSearchParams({
      selectedUnit: selectedUnit,
      item: JSON.stringify(item),
      dropdownOptions: dropdownOptions,
      unitAddress: unitAddress,
      unitid: unitId
      // item: item,
    }).toString();

    history.push(`/request?${queryParams}`);
  };

  const onDoctorClick = (doctor) => {
    console.log('rrrrrrrr', doctor);
    setOpenPopup(true);
    setDoctor(doctor);
  }

  return (
    <Container style={{ padding: 0, margin: 0, backgroundColor: '#fff' }}>
      <Header
        dropdownOptions={dropdownOptions}
        selectedUnit={selectedUnit}
        unitAddress={unitAddress}
        unitid={unitId}
        city={city}
      />
      <Box sx={{ padding: 0 }}>
        {/* Specialties Section */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mb: 3, backgroundColor: '#ffe6f2', height: '60px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderRadius: '50px', boxShadow: 1, backgroundColor: '#f5f5f5', alignItems: 'center', mb: 2, height: '30px' }}>
            <InputBase
              placeholder="Search for doctors and specialties"
              sx={{ ml: 2, flex: 1, fontFamily: 'Poppins', fontSize: '14px', color: COLORS.textColor }}
            />
            <IconButton sx={{
              p: '10px', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}>
              <SearchIcon sx={{ color: COLORS.primaryColor }} />
            </IconButton>
          </Box>
          <IconButton sx={{
            ml: 2, mb: 2, ':focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
            },
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':active': {
              boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
            },
          }}>
            <FilterListIcon sx={{ color: COLORS.primaryColor }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          {/* <LocationOnIcon sx={{}} /> */}
          <Select
            value={selectedUnit}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select location' }}
            IconComponent={ArrowDropDownIcon}
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <LocationOnIcon sx={{ color: COLORS.primaryColor, fontSize: '16px' }} />
                  </InputAdornment>
                }
                sx={{
                  height: '25px',
                  fontFamily: 'Poppins',
                  color: COLORS.textColor,
                  fontSize: '13px',
                  alignItems: 'center',
                  borderRadius: '9px',
                  ':hover': {
                    backgroundColor: 'transparent',
                  },
                  ':active': {
                    boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Corrected syntax for boxShadow
                  },
                }}
              />
            }
          >
            <MenuItem disabled value="" sx={{ fontFamily: 'Poppins', fontSize: '13px', }}>
              {selectedUnit}
            </MenuItem>
            {dropdownOptions.map(option => (
              <MenuItem key={option.id} value={option.UnitName} sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '13px', }}>
                {option.UnitName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Typography variant="h6" sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}>
            Specialties
          </Typography>
          <Button
            sx={{
              mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'View Less' : 'View all'}
          </Button>
        </Box>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {displayedSpecialties.map((specialty, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 2,
                  boxShadow: 1,
                  borderRadius: 2
                }}
                onClick={() => handleSpecialtyClick(specialty._id.$oid, unitId, specialty.department)}
              >
                <Avatar src={Images.generalMedicine} sx={{ marginRight: 2 }}>
                  {/* <LocalHospitalIcon /> */}
        {/* </Avatar>
                <CardContent sx={{ padding: 0 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                  >
                    {specialty.department}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: COLORS.placeholderColor, fontFamily: 'Poppins' }}
                  >
                    {specialty.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid> */}

        {/* Doctors Section */}
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="h6"
            sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}
          >
            Doctors
          </Typography>
          <Button
            sx={{
              color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
              },
              ':hover': {
                backgroundColor: 'transparent',
              },
              ':active': {
                boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
              },
            }}
            onClick={() => setShowAll1(!showAll1)}
          >
            {showAll1 ? 'View Less' : 'View all'}
          </Button>
        </Box>
        <Grid container spacing={2}>
          {displayedDoctors.map((doctor, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  boxShadow: 1,
                  borderRadius: 2
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <Avatar src={doctor.photo} sx={{ marginRight: 2, width: 85, height: 90 }} />
                  <CardContent sx={{ padding: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                    >
                      {doctor.first_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: COLORS.textColor, fontFamily: 'Poppins', fontSize: '11px' }}
                    >
                      {doctor.default_specialization}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: COLORS.placeholderColor, fontFamily: 'Poppins' }}
                    >
                      {doctor.languages ?
                        (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                        'Tamil, English'
                      }
                    </Typography>
                  </CardContent>
                </Box>
                <Button
                  onClick={() => handleSlots(selectedUnit, doctor)}
                  sx={{
                    backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
                    color: 'white',
                    fontFamily: 'Poppins',
                    minWidth: '100px',
                    fontSize: '12px',
                    padding: '1px 14px',
                    textTransform: 'none',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '15px',
                    '&:hover': {
                      backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
                    },
                    ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                    },
                    // ':hover': {
                    //   backgroundColor: COLORS.primaryColor,
                    // },
                    ':active': {
                      boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                    },
                  }}
                >
                  {doctor.request_only === 0 ? 'Book Now' : 'Request'}
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '98%',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            padding: '20px',
            mt: 1,
            ml: 1,
            mb: 1,
          }}
        >
          <Grid container spacing={4}>
            {/* Featured Specialty Areas */}
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}>
                  Specialties
                </Typography>
              </Box>
              {/* <Grid container spacing={2} sx={{ marginBottom: 2 }}> */}
              {displayedSpecialties.map((specialty, index) => (
                // <Grid item xs={12} md={6} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '7px',
                    cursor: 'pointer',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                      boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                    },
                    backgroundColor: activeSpecialty === specialty._id.$oid ? '#ffe6f2' : 'transparent',
                  }}
                  onClick={() => handleSpecialtyClick(specialty._id.$oid, unitId, specialty.department, specialty)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={Images.generalMedicine} sx={{ marginRight: 2 }}>
                      {/* <LocalHospitalIcon /> */}
                    </Avatar>

                    {/* <CardContent sx={{ padding: 0 }}> */}
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                      >
                        {specialty.department}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: COLORS.placeholderColor, fontFamily: 'Poppins' }}
                      >
                        {specialty.description}
                      </Typography>
                    </Box>
                    {/* </CardContent> */}
                  </Box>
                </Box>
                // </Grid>
              ))}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  sx={{
                    mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                    },
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? 'View Less' : 'View all'}
                </Button>
              </Box>
              {/* </Grid> */}
            </Grid>

            {/* Top Doctors */}
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mb: 2, color: COLORS.primaryColor, fontFamily: 'Poppins', fontSize: '22px' }}>
                  Doctors
                </Typography>
              </Box>
              {displayedDoctors.map((doctor, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '7px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                      boxShadow: '0 0.5vw 1vw rgba(0,0,0,0.2)',
                    },
                  }}
                  onClick={() => onDoctorClick(doctor)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      alt={doctor.name}
                      src={doctor.photo}
                      sx={{ marginRight: 2, width: 85, height: 90 }}
                    />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'normal', color: COLORS.textColor, fontFamily: 'Poppins' }}
                      >
                        {doctor.first_name}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                      >
                        {Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: COLORS.textColor, fontFamily: 'Poppins', fontSize: '11px' }}
                      >
                        {doctor.default_specialization}
                      </Typography>
                      {/* <Typography variant="body2" sx={{ color: '#777' }}>
                                            {doctor.spe}
                                        </Typography> */}
                      <Typography
                        variant="caption"
                        sx={{ color: COLORS.textColor, fontFamily: 'Poppins' }}
                      >
                        Language: {doctor.languages ?
                          (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                          'Tamil, English'
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    onClick={() => {
                      if (doctor.request_only === 0) {
                        handleSlots(selectedUnit, doctor);
                      } else {
                        handleReq(selectedUnit, doctor);
                      }
                    }}
                    sx={{
                      backgroundColor: doctor.request_only === 0 ? '#962067' : '#ffd700',
                      color: 'white',
                      fontFamily: 'Poppins',
                      minWidth: '100px',
                      fontSize: '12px',
                      padding: '1px 14px',
                      textTransform: 'none',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      borderRadius: '15px',
                      '&:hover': {
                        backgroundColor: doctor.request_only === 0 ? '#740150' : '#e6c200',
                      },
                      ':focus': {
                        outline: 'none',
                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Change this to `transparent` if you want no color
                      },
                      // ':hover': {
                      //   backgroundColor: COLORS.primaryColor,
                      // },
                      ':active': {
                        boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                      },
                    }}
                  >
                    {doctor.request_only === 0 ? 'Book Now' : 'Request'}
                  </Button>
                </Box>
              ))}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  sx={{
                    mb: 2, color: COLORS.primaryColor, textTransform: 'none', fontFamily: 'Poppins', ':focus': {
                      outline: 'none',
                      boxShadow: `0 0 0 2px transparent`, // Change this to `transparent` if you want no color
                    },
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                    ':active': {
                      boxShadow: `0 0 0 2px ${COLORS.primaryColor}`, // Keeps the focus ring on active state
                    },
                  }}
                  onClick={() => setShowAll1(!showAll1)}
                >
                  {showAll1 ? 'View Less' : 'View all'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal open={openPopup} onClose={onClose} aria-labelledby="doctor-popup" aria-describedby="doctor-details">

                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', sm: '70%', md: '70%' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none',
                    maxHeight: '80%',
                    overflowY: 'auto'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <Typography variant="h6" id="doctor-popup" sx={{ color: COLORS.primaryColor, fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '16px', }}>
                            About the doctor
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon color={COLORS.primaryColor} />
                        </IconButton>
                    </Box>
                    {doctor ? (
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    {/* <Avatar src={doctor[0].photo} alt={doctor.first_name} sx={{ width: 60, height: 60, mr: 2 }} />
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{doctor[0].first_name}</Typography> */}
                                    {/* <Typography variant="body2" color="textSecondary">{doctor && doctor.designation.join(', ')}</Typography> */}
                                    {/* </Box> */}

                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', padding: 0, flexDirection: { xs: 'row' }, flexWrap: 'wrap', backgroundColor: '#ffe6f2', mt: 1, boxShadow: '0 0.5vw 1vw rgba(0.2,0.2,0.2,0.2)',}}> */}
                                    <Avatar
                                        src={doctor.photo ? doctor.photo : ''}
                                        alt="Doctor Image"
                                        sx={{ width: 50, height: 55, marginRight: 2, ml: 1, mt: 1, }}
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h6" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontWeight: 'normal', fontSize: '15px' }}>{doctor.first_name}</Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{Array.isArray(doctor.designation) ? doctor.designation.join(', ') : doctor.designation}</Typography>
                                    </Box>
                                    <Box sx={{ ml: 8 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}>{doctor.default_specialization}</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                    <Box sx={{ ml: 12 }}>
                                        <Typography variant="body2" sx={{ fontFamily: 'Poppins', color: COLORS.textColor, fontSize: '12px' }}><GTranslateIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} />{doctor.languages ?
                                            (Array.isArray(doctor.languages) ? doctor.languages.join(', ') : doctor.languages) :
                                            'Tamil, English'
                                        }</Typography>
                                        {/* <Typography variant="body2" sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center', color: COLORS.textColor, fontSize: '12px' }}><AccessTimeIcon sx={{ mr: 1, color: COLORS.textColor, fontSize: '16px' }} /> {doctorSlot && doctorSlot.length > 0 ? doctorSlot[0].SlotTiming : ''}</Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                    {/* <Typography variant="body2">{doctor.default_specialization}</Typography> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                        {/* <Typography variant="body2">{doctor.available_time.start_time && doctor.available_time.start_time}-{doctor.available_time.end_time && doctor.available_time.end_time}</Typography> */}
                                    </Box>
                                </Box>

                                <Typography variant="body2" sx={{ mt: 2, color: COLORS.textColor, fontFamily: 'Poppins', textAlign: 'justify' }}>
                                    {doctor.description}
                                </Typography>
                            </Box>
                        </Box>
                    ) : ('')}
                </Box>
            </Modal>
    </Container>
  );
};

export default SpecialtiesDoctor;
